// eslint-disable
// @ts-nocheck
import React, { useState, useContext, useEffect } from "react";
import NFTImageUploader from "./ImageUploader";
import NFTForm from "./NFTForm";
import "./CreateNFT.css";
import { MetaplexContext } from "../../../Providers/MetaplexContextProvider";
import {
  createNft,
  fetchUserNft,
  fetchUserNftModel,
} from "../../../Api/Solana/nft";
import { createCollectionNft } from "../../../Api/Solana/nft";
import { NFTMintedSuccess } from "./NFTMintedSuccess";
import Swal from "sweetalert2";
import { useWallet } from "@solana/wallet-adapter-react";
import { meta } from "@dicebear/avatars-identicon-sprites";
import AddNftModal from "./AddNftModal";
import Button from "react-bootstrap/Button";
const CreateNFT = (props) => {
  const metaplex = useContext(MetaplexContext);
  const [file, setFile] = useState(undefined);
  const [url, setUrl] = useState(undefined);
  const [addNftsToCollection, setAddNftsToCollection] = useState([]);
  const [ownerNfts, setOwnerNfts] = useState([]);

  const [minting, setMinting] = useState(false);
  const [mintedSuccessfully, setMintedSuccessfully] = useState(false);

  const [createdNFT, setCreatedNFT] = useState({});
  const [addedNfts, setAddedNfts] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const wallet = useWallet();

  const mintAgain = () => {
    console.log("mint again");
    setUrl(undefined);
    setFile(undefined);
    setMinting(false);
    setMintedSuccessfully(false);
  };

  const fileChangeHandler = (event) => {
    setFile(event.target.files[0]);
    setUrl(URL.createObjectURL(event.target.files[0]));
    // incrementStep();
  };

  const removeFile = () => {
    setFile(undefined);
    setUrl(undefined);
    setAddedNfts([]);
  };
  const onSubmit = async (data) => {
    // incrementStep();

    // setOpenModal(true);

    if (metaplex.publicKey) {
      setMintedSuccessfully(false);
      var metaData = !addedNfts.length
        ? {
            name: data.name,
            symbol: data.symbol,
            description: data.description,
            seller_fee_basis_points: data.royalty * 100,
            image: file,
            attributes: [],
            properties: {
              creators: [{ address: metaplex.publicKey, share: "100" }],
            },
          }
        : {};
      setMinting(true);
      try {
        // console.log(data.addedNfts, "addNftsToCollection");
        if (props.isCollection) {
          console.log(metaData, "updated metadata");
          //@ts-ignore
          const nft = await createCollectionNft(
            metaplex,
            metaData,
            wallet,
            data.addedNfts,
            addedNfts
          );
          console.log("Minted NFT", nft);
          // setMintedSuccessfully(true);
          setCreatedNFT(nft);
        } else {
          const nft = await createNft(metaplex, metaData);
          setMintedSuccessfully(true);
          setCreatedNFT(nft);
        }
      } catch (error) {
        setMinting(false);
        setMintedSuccessfully(false);
      }
      setMinting(false);
    } else {
      Swal.fire({
        title: "Error!",
        text: "Wallet is not detected, Please connect your wallet!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.error("Please auth befor login");
    }
  };

  useEffect(() => {
    const loadMyNFT = async () => {
      const nfts = await fetchUserNftModel(metaplex);

      if (nfts !== null && nfts !== undefined && nfts) {
        setOwnerNfts(nfts);
      }
    };

    loadMyNFT(metaplex);

    metaplex && loadMyNFT();
    console.log(ownerNfts, "ownerNfts create nft");
  }, [metaplex]);
  const addNfts = (nfts) => {
    // 👇️ take parameter passed from Child component
    console.log(nfts, "selected mint address");
    setAddedNfts(nfts);
  };

  console.log(props.ownerNfts, "ownerNfts create nft");
  return (
    <>
      {/* <div style={{ padding: "0px 0 90px" }}> */}
      <div style={{ minHeight: 500 }}>
        {mintedSuccessfully ? (
          <NFTMintedSuccess mintAgain={mintAgain} mintedNFT={createdNFT} />
        ) : !url && !addedNfts.length ? (
          <div>
            <NFTImageUploader handleFileChange={fileChangeHandler} />
            {props.isCollection && (
              <Button variant="primary" onClick={() => setModalShow(true)}>
                Add to existing collection NFT
              </Button>
            )}
            <AddNftModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              ownerNfts={ownerNfts}
              addNfts={addNfts}
              isCollection={true}
            />
          </div>
        ) : (
          <div className="container m-auto row">
            <div className="col-md-5">
              <div
                className="position-relative mb-5"
                style={{ width: "fit-content", margin: "auto" }}
              >
                <button onClick={removeFile} className="nft-remove-btn">
                  X
                </button>
                <img
                  src={url || addedNfts[0].json.image}
                  className="nft-image-preview"
                  alt="Selected NFT"
                />
              </div>
            </div>

            <div className="col-md-7" style={{ paddingLeft: "48px" }}>
              <NFTForm
                isCollection={props.isCollection}
                minting={minting}
                onSubmit={onSubmit}
                ownerNfts={ownerNfts}
                collectionNft={addedNfts[0]}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateNFT;
