import { PublicKey } from "@solana/web3.js";
import { sol } from "@metaplex-foundation/js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useWallet } from "@solana/wallet-adapter-react";

export const bidNFT = async ({
    metaplex,
    auctionHouse,
    mintAddress,
    tradeStateAddress,
    price
}) => {
    try {
        console.log(metaplex, auctionHouse, mintAddress, tradeStateAddress, price)


        const listing = await metaplex
            .auctionHouse().findListingByTradeState({
                tradeStateAddress: tradeStateAddress,
                auctionHouse
            });
        console.log(listing, "listing")
        if (listing.purchaseReceiptAddress === null && listing.canceledAt === null) {

            const { bid, buyerTradeState, receipt } = await metaplex
                .auctionHouse()
                .bid({
                    auctionHouse,
                    buyer: metaplex.identity().publicKey,
                    seller: listing.sellerAddress,
                    mintAccount: mintAddress,
                    price: sol(price),
                    printReceipt: true,
                })

            console.log(bid, listing.price.basisPoints.toNumber());

            await Swal.fire({
                icon: "success",
                title: "Successfully bidded",
                confirmButtonText: "OK",

            });

        } else {
            Swal.fire("Invalid Listing")
        }





    } catch (error) {
        console.log(error);
    }
};
