import { useState, useContext } from "react";
import { listNFT } from "../../../Api/Solana/auctionHouse";
import { MetaplexContext } from "../../../Providers/MetaplexContextProvider";
import { AuctionHouseContext } from "../../../Providers/AuctionHouseContextProvider";
import { ListNFTModal } from "../Modal/ListNFTModal";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { cancelListing } from "../../../Api/Solana/cancelListing";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { Identicon } from "./Identicon";
import { useTranslation } from "react-i18next";
import axios from "axios";


const NFTCard = (props) => {
  // const [image, setImage] = useState(undefined);

  const [showListingModal, setShowListingModal] = useState(false);
  const [showCancellingModal, setShowCancellingModal] = useState(false);
  const [data, setData] = useState(props.data);
  const { metaplex, publicKey, connection } = useContext(MetaplexContext);
  const { auctionHouse, listedMintAddress, loadingAuctions, listedNfts } = useContext(AuctionHouseContext);
  const [isNftListing, setIsNftListing] = useState(false);
  const [isNftCancelling, setIsNftCancelling] = useState(false);
  const [checking, setChecking] = useState(false);

  const { t } = useTranslation();

  const listMyNft = async (price) => {
    setIsNftListing(true);
    let resObj = undefined;
    try {

      resObj = await listNFT(
        { metaplex, auctionHouse },
        { address: props.data.mintAddress, price: price.price }
      );//listingReceipt contains something called listingAddress, tradeStateAddress: Unique and one time generation.
      console.log(resObj, "listing resp")
      console.log('TradeState is here', resObj.sellerTradeState.toString());
      if (resObj !== undefined) {
        axios.post("http://10.39.1.169:3000/auction/update_listings", resObj)
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            if (error.response) {
              //response status is an error code
              console.log(error.response.status);
            }
            else if (error.request) {
              //response not received though the request was sent
              console.log(error.request);
            }
            else {
              //an error occurred when setting up the request
              console.log(error.message);
            }
          });
      }

      Swal.fire({
        title: "Success!",
        text: "NFT Listed Successfully!",
        icon: "success",
        confirmButtonText: "Close",
      });
      setShowListingModal(false);
      //window.location.reload();
    } catch (error) {
      console.log(error, "Error occured");
    }
    setIsNftListing(false);
  };



  const cancelMyNft = async () => {

    setIsNftCancelling(true);
    try {
      if (publicKey) {
        console.log(data.mintAddress?.toString(), "Cancelling...");

        const filteredTradeState = listedNfts.filter((listedNft) => {
          return listedNft.mintAddress.toString() === data.mintAddress?.toString()
        })

        const cancelObj = {
          metaplex: metaplex,
          auctionHouse,
          tradeStateAddress: filteredTradeState[0].tradeStateAddress
        };
        console.log(cancelObj, 'cancel obj');
        cancelListing(cancelObj)
          .then((res) => {
            console.log(res);
            if (res && res.signature) {
              Swal.fire({
                title: "Success!",
                text: "You cancelled the NFT listing Successfully!",
                icon: "success",
                confirmButtonText: "Close",
              });
              setShowCancellingModal(false);
            } else {
              console.log("Error");
            }
          })
      } else {
        Swal.fire({
          title: "Error!",
          text: "Wallet is not detected, Please connect your wallet!",
          icon: "error",
          confirmButtonText: "Close",
        });
      }


    } catch (error) {
      console.log("Error occured");
    }


    setIsNftCancelling(false);
  };


  const checkNft = async (image) => {
    const response = await fetch(image);
    const blob = await response.blob();
    console.log(blob, `${image.toString().split("/")[3]}.${blob.type.split("/")[1]}`);
    const fileImage = new File([blob], 'image.jpg', { type: blob.type });
    var formdata = new FormData();
    formdata.append("", fileImage, `${image.toString().split("/")[3]}.${blob.type.split("/")[1]}`);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    const AWS_ENDPOINT = 'https://api.cloudns.ph/detect'
    const LOCAL_ENDPOINT = 'http://localhost:5000/detect'
    try {

      Swal.fire({
        title: "Validating",
        // timer: 5000,
        timerProgressBar: true,
        text: `Checking your nft.`,
        showConfirmButton: false,
      })

      fetch(AWS_ENDPOINT, requestOptions)
        .then(response => response.json())
        .then(data => {
          Swal.close()
          if (response.status >= 200 && response.status <= 299) {
            console.log(data, "image response")
            if (data.safe > 0.6) {
              setShowListingModal(true)
            } else {
              Swal.fire({
                title: "Error!",
                text: "Found NSFW content, please list appropriate content",
                icon: "error",
                confirmButtonText: "Close",
              });

            }

          } else if (response.status >= 500) {
            Swal.fire({
              title: "Error!",
              text: "Error listing nft.Try again later",
              icon: "error",
              confirmButtonText: "Close",
            });
          } else if (response.status === 413) {
            Swal.fire({
              title: "Error!",
              text: "Error listing nft image too big!.",
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        })
    } catch (e) {
      Swal.fire({
        title: "Error!",
        text: `${e}`,
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  }







  return (
    <div className="top-collection-item">
      <div className="collection-item-top">
        <ul>
          <li className="avatar">
            <div className="thumb">
              {data?.creators[0] && <Identicon
                alt=""
                style={{
                  width: '24px',
                  height: '24px',
                  marginRight: '10px',
                  marginTop: '2px',
                }}
                address={data?.creators[0]?.address?.toString() ?? 'nAN'} />}
            </div>
            By {data?.creators[0] !== undefined || data?.creators?.length !== 0 ? data?.creators[0]?.address?.toString().slice(0, 3) + "..." + (data?.creators[0]?.address?.toString().slice(-3)) : 'Undefined'}
            {/* <Identicon props={publicKey} /> */}
            {/* <a href="/author-profile" className="thumb">
              <img src="" alt="" />
            </a>
            By{" "}
            <a href="/" className="name">
              MVP APPs
            </a> */}
          </li>
          {/* <li className="info-dots dropdown">
            <span />
            <span />
            <span /> */}
          {/* <a href="/#" className="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" /> */}
          {/* <ul className="dropdown-menu">
              <li>
                <a href="/nft-marketplace">Artwork</a>
              </li>
              <li>
                <a href="/nft-marketplace">Action</a>
              </li>
              <li>
                <a href="/nft-marketplace">Author Action</a>
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
      <div className="collection-item-thumb">
        {listedMintAddress.includes(data.mintAddress.toString()) ?
          <Link
            // to={{ pathname: `/view-nft/${data?.mintAddress}`, state: { data } }}
            to={`/my-nfts/view-nft/${data.mintAddress}`}
            state={{ id: publicKey.toString(), viewOnly: false, sellerData: data }}

          >
            <img src={data?.uri} alt="" style={{ width: "500%", height: "600%" }} />
          </Link> :
          <img src={data?.uri} alt="" style={{ width: "500%", height: "600%" }} />

        }
      </div>
      <div className="collection-item-content">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

          {data?.name && data?.name.length > 30 ?
            <Marquee gradient={false}   >
              <h5
                className="title"

                style={{
                  // color: '#FBFAFA',
                  // fontSize: '16px',
                  // fontWeight: '600',
                  // display: 'flex',
                  // justifyContent: 'space-between',
                  // textTransform: 'capitalize',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }
                }>{data.name}<span></span></h5>
            </Marquee>
            : <h5 className="title"> {data.name}  </h5>}

        </div>


        <div style={{ justifyContent: 'flex-start' }}>
          {data?.description && data?.description.length > 30 ? <Marquee className="title" gradient={false} >
            <h3 style={{
              color: '#E039FD',
              fontSize: '15px',
              fontWeight: '200',
              marginTop: '10px',
              whiteSpace: 'nowrap',
              // overflow: 'auto',
            }}><span>{data?.description}</span></h3>
          </Marquee> : <h3 style={{
            color: '#E039FD',
            fontSize: '14px',
            fontWeight: '200',
            marginTop: '10px',
            whiteSpace: 'nowrap',
            // overflow: 'auto',
          }}><span>{data?.description}</span></h3>}
        </div>
      </div>

      <ListNFTModal
        showListingModal={showListingModal}
        listMyNft={listMyNft}
        setShowListingModal={setShowListingModal}
        data={data}
        isNftListing={isNftListing}
      />

      <div className="collection-item-bottom">
        <ul>
          {listedMintAddress.includes(data.mintAddress.toString()) ?
            <li
              className="bid">
              <Link
                // to={`/view-nft/${data.mintAddress}`}
                to={`/my-nfts/view-nft/${data.mintAddress}`}
                state={{ id: publicKey.toString(), viewOnly: false, sellerData: data }}
                className="btn"
              >
                {t('view')
                }
              </Link>
            </li> :
            <li className="bid">

              <button
                onClick={
                  () =>
                    // listedMintAddress.includes(data.mintAddress.toString()) ?

                    // window.location.href = '/view-nft/' + data.mintAddress.toString()
                    // cancelMyNft()
                    // :
                    // setShowListingModal(true)
                    checkNft(data.uri)

                }

                className="btn"
              // disabled={loadingAuctions}
              >
                {
                  // listedMintAddress.includes(data.mintAddress.toString())
                  //   ?
                  // (isNftCancelling
                  //   ? "Delisting"
                  //   : "delist")
                  // :
                  (isNftListing
                    ? t('listing')
                    : t('list'))
                }

                {/* {isNftListing ? "Listing..." : loadingAuctions ? "Auction House is Loading..." : "List"} */}
              </button>
            </li>}
          {/* <li className="wishlist">
            <a href="/login-register">10</a>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default NFTCard;