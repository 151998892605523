import React, { useState, useEffect } from 'react'
import BannerWithLinks from '../IndexOne/BannerWithLinks'
import Airtable from "airtable";
import { useWallet } from '@solana/wallet-adapter-react';
import axios from 'axios'
import { notification } from 'antd/dist/antd';
import { WithContext as ReactTags } from 'react-tag-input';
import Layout from '../Layout/MainLayout';
import { useTranslation } from 'react-i18next';
import Form from "react-bootstrap/Form";
import i18n from "i18next";

var url = []
//keyhdxayfOZmIv6nu airtable api key
const ApplyCollection = () => {
    const [formData, setFormData] = useState({
        file: [],
        name: '',
        size: null,
        attributes: [],
        company: '',
        email: '',
        phone: '',
        description: '',
        royalty: 8,
        royaltySplit1: "",
        royaltyShare1: "",
        royaltySplit2: "",
        royaltyShare2: "",
        // royaltySplit3: "",
        // royaltyShare3: "",
        instagram: "",
        twitter: "",
        facebook: "",
        youtube: "",
        tiktok: "",
        discord: "",
        website: "",
    })
    const [file, setFile] = useState();
    const { publicKey, connected } = useWallet();
    const [images, setImages] = useState([]);
    const [uploaded, setUploaded] = useState(false);
    const [progress, setProgress] = useState(0);
    const [progressLoading, setProgressLoading] = useState();
    const [progressTotal, setProgressTotal] = useState();
    const [creators, setCreators] = useState([{ pub_key: "", split: "" }])
    const [firstSplit, setFirstSplit] = useState(undefined);
    const [secondSplit, setSecondSplit] = useState(undefined);
    const min = 1;
    const max = 100;
    const [firstCreator, setFirstCreator] = useState(undefined);
    const [secondCreator, setSecondCreator] = useState(undefined);
    const maxRoyalty = 100;
    const { t } = useTranslation();

    const handleChange = (e) => {

        setFormData({ ...formData, [e.target.name]: e.target.value })
        console.log('form data', formData)

    }

    const handleSplits = (e) => {

        setFirstSplit(undefined);
        setSecondSplit(undefined);
        var val1 = Math.max(min, Math.min(max, Number(parseInt(e.target.value))));
        console.log('highest value is', val1);

        if (creators.length > 1) {
            //If there is more than one creator
            if (e.target.name === 'royaltyShare1') {
                var val = maxRoyalty - val1;
                setSecondSplit(val);
                setFirstSplit(val1);
                setFormData({ ...formData, "royaltyShare1": firstSplit })
                setFormData({ ...formData, "royaltyShare2": secondSplit })
                console.log('value calculated split1', formData.royaltyShare1)
            } else {
                setSecondSplit(val1);
                var val = maxRoyalty - val1;
                setFirstSplit(val);
                setFormData({ ...formData, "royaltyShare1": firstSplit })
                setFormData({ ...formData, "royaltyShare2": secondSplit })
                console.log('value calculated split2', formData.royaltyShare2)

            }
        } else {
            //One creator only
            setFirstSplit(100);
        }

        // LOGIC FOR 3 CREATORS
        // if (creators.length > 1) {
        //     var item = maxRoyalty - e.target.value;
        //     var item2 = item / (creators.length - 1);
        //     setFormData({ ...formData, [e.target.name]: item2 })
        // }
    }


    const uploadImages = async (e) => {

        var files = e.target.files;

        if (files.length > 20) {
            notification.info({
                message: <span style={{ color: 'black' }}>Please upload as zip/rar </span>,
                description: (
                    <span style={{ color: 'black', opacity: 0.5 }}>Images more than 20 upload as a zip/rar file.</span>
                ),
                duration: 0,

                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });

        } else {
            const data = new FormData()
            for (var i = 0; i < files.length; i++) {

                setProgressLoading(i + 1)
                setProgressTotal(files.length)
                setImages(images => [...images, URL.createObjectURL(files[i])]);
                console.log('object url', URL.createObjectURL(files[i]));
                console.log('new file', files[i])

                console.log(i, 'loaded data')


                data.append("file", files[i])
                data.append("upload_preset", "lyvzryc7")
                data.append("cloud_name", "mvpappsnft")
                // for (var key of data.entries()) {
                //     console.log('meowies', key[0] + ', ' + key[1])
                // }
                // console.log(data, 'data ....')

                const options = {
                    url: 'https://api.cloudinary.com/v1_1/mvpappsnft/auto/upload/',
                    method: 'POST',
                    data: data,
                    onUploadProgress: (p) => {
                        const percentage = (p.loaded) / p.total
                        console.log("progress", percentage.toFixed(2));

                        setProgress(percentage.toFixed(2))
                        console.log("progress", progress * 100 + "%");

                    }
                };

                await axios(options)
                    // .then(resp => console.log("resp",resp.status))
                    .then(resp => {
                        console.log("resp", resp.status)
                        var file = { url: resp.data.url }
                        setProgress(1.0)

                        url.push(file);
                        console.log("url ", resp.data.url, "pushed x", url.length)
                        console.log("progress complete", progress * 100 + "%");
                    })
                    .catch(err => console.log(err))

            }
        }
        // url.pop()

        console.log(url, "upload url .....")
    }

    const submitForm = (e) => {
        console.log('entire formData', formData);
    }


    const handleSubmit = (e) => {

        console.log(url, "submit url .....")

        if (!connected)
            // alert("wallet not connected")
            notification.info({
                message: <span style={{ color: 'black' }}>Connect your wallet o submit form</span>,
                description: (
                    <span style={{ color: 'black', opacity: 0.5 }}>wallet not connected</span>
                ),
                duration: 0,

                onClick: () => {
                    console.log('Notification Clicked!');
                },
            });

        else {

            e.preventDefault()
            if (formData.royalty < 0 || formData.royalty > 100) {
                // alert("Royality should be between 0-100")
                notification.error({
                    message: <span style={{ color: 'black' }}>Invalid data</span>,
                    description: (
                        <span style={{ color: 'black', opacity: 0.5 }}>Royality should be between 0-100</span>
                    ),

                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });

            } else {
                if (formData.royaltyShare1 + formData.royaltyShare2 + formData.royaltyShare3 == 100) {
                    var base = new Airtable({ apiKey: 'keyhdxayfOZmIv6nu' }).base('app2rgExJh3a8hLlq');

                    base('Teams').create([
                        {
                            "fields": {
                                "Collection Name": formData.name,
                                "Collection Size": formData.size,
                                "Collection Description": formData.description,
                                "Initial Sale Price": formData.price,
                                "Assets": url,
                                "Email": formData.email,
                                "Phone": formData.phone,
                                "Company": formData.company,
                                "Royalty": formData.royalty,
                                "Royalty Split 1": formData.royaltyShare1,
                                "PubKey 1": formData.royaltySplit1,
                                "Royalty Split 2": formData.royaltyShare2,
                                "PubKey 2": formData.royaltySplit2,
                                "Royalty Split 3": formData.royaltyShare3,
                                "PubKey 3": formData.royaltySplit3
                            }
                        }

                    ], function (err, records) {
                        if (err) {
                            // alert(err);
                            notification.error({
                                message: <span style={{ color: 'black' }}>Error</span>,
                                description: (
                                    <span style={{ color: 'black', opacity: 0.5 }}>{err}</span>
                                ),

                                onClick: () => {
                                    console.log('Notification Clicked!');
                                },
                            });
                            return;
                        }
                        records.forEach(function (record) {

                            // alert("You have successfully submited you collection");
                            notification.success({
                                message: <span style={{ color: 'black' }}>Form submission successful</span>,
                                description: (
                                    <span style={{ color: 'black', opacity: 0.5 }}>You have successfully submited you collection</span>
                                ),
                                duration: 0,

                                onClick: () => {
                                    console.log('Notification Clicked!');
                                },
                                onClose: () => {
                                    window.location.href = "/";
                                }
                            });
                            console.log("Your submission id is ", record.getId());

                        });
                    });
                } else {
                    // alert("Royality should be total of 100%")
                    notification.error({
                        message: <span style={{ color: 'black' }}>Invalid data</span>,
                        description: (
                            <span style={{ color: 'black', opacity: 0.5 }}>Royality should be total of 100%</span>
                        ),
                        duration: 0,

                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });

                }
            }
        }

    }

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    return (
        <Layout>
            <BannerWithLinks noFilter title='Apply For Collection' />

            <div className="create-item-area">
                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-xl-9 col-lg-8">
                            <h4>Already have NFTs and want to add them to a collection?</h4>
                            <div className="home-back-btn my-5">
                                <a href="/create-collection" style={{ color: 'white' }}>Link My NFTs to a Collection</a>
                            </div>

                            <form onSubmit={handleSubmit} className="create-item-form">
                                <div className="form-grp">
                                    <div
                                        className="row justify-content-center"
                                    >{
                                            images?.length > 0 ? images.map((image, index) => (
                                                <div
                                                    key={index}
                                                    // style={{ position: 'relative', height: 110, width: 110, }}
                                                    style={{ position: 'relative', height: 150, width: 150 }}
                                                >

                                                    <img
                                                        src={image}
                                                        // height="110px"
                                                        // width="110px"
                                                        // height="100%"
                                                        // width='100%'
                                                        style={{ borderRadius: '10px' }}
                                                    />
                                                    <button onClick={() => setImages(images.filter((item) => {
                                                        return item !== images[index]
                                                    }))} style={{
                                                        position: 'absolute',
                                                        top: -8,
                                                        right: 4,
                                                        border: 'none',
                                                        backgroundColor: 'transparent'
                                                    }}> <i className="fa fa-minus-circle" height="20px" width="20px" style={{
                                                        color: "#e039fd",
                                                        boxShadow: "0px 0px 2px 2px black",
                                                        borderRadius: '50%',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'row'
                                                    }} /></button>

                                                </div>
                                            )) : <></>
                                        }</div>
                                    <label for="file">{t('upload_file')}*</label>
                                    <input onChange={uploadImages} name='file' id="file" type="file" multiple
                                        accept="image/*,.zip,.rar,.7zip " required />
                                    {images.length > 0 && <div className="file-progress" style={{ border: "solid 2px black" }}>
                                        <div className="file-progress-inner" style={{ height: "1em", width: (progress && progress * 100 + "%"), backgroundColor: "white" }} />
                                        <div>
                                        </div>
                                        {progress ? <h5>{progress && progress * 100 + "%"}</h5> : null}
                                        {progressTotal ? <h5>{progressTotal && "[" + progressLoading + "/" + progressTotal + "]"}</h5> : null}

                                    </div>}
                                </div>

                                <div className="form-grp">
                                    <label for="name">{t('collection_name')}</label>
                                    <p className="collection-desc">{t('collection_name_info')}</p>
                                    <input value={formData.name} onChange={handleChange} name='name' id="name" type="text" placeholder="Enter Collection Name" required />
                                </div>
                                <div className="form-grp">
                                    <label for="size">{t('collection_size')}</label>
                                    <p className="collection-desc">{t('collection_size_info')}</p>

                                    <input value={formData.size} onChange={handleChange} name='size' max={10000} id="size" type="number" placeholder="Enter Collection Size (max 10k)" required />
                                </div>
                                <div className="form-grp">
                                    <label for="price">{t('initial_sale_price')}</label>
                                    <p className="collection-desc">{t('initial_sale_price_info')}</p>
                                    <input value={formData.price} onChange={handleChange} name='price' id="price" type="number" placeholder="Enter Initial Sale Price" required />
                                </div>
                                <div className="form-grp">
                                    <label for="description">{t('desc')}</label>
                                    <p className="collection-desc">{t('desc_tip')}</p>
                                    <textarea
                                        value={formData.description}
                                        onChange={handleChange}
                                        name='description'
                                        id="description"
                                        type="text"
                                        placeholder={t('collection_desc')}
                                        required
                                    />
                                </div>
                                <div className="form-grp">
                                    <label for="attributes">{t('attributes')}</label>
                                    <p className="collection-desc">{t('attributes_info')}</p>
                                    <div>
                                        <ReactTags
                                            tags={formData.attributes}
                                            handleDelete={(i) => setFormData({ ...formData, attributes: formData.attributes.slice(0).filter((tag, index) => index !== i) })}
                                            handleAddition={(tag) => setFormData({ ...formData, attributes: [...formData.attributes.slice(0), tag] })}
                                            inputFieldPosition="top"
                                            placeholder={t('add_attributes')}

                                        />
                                    </div>
                                </div>
                                <div className="form-grp">
                                    <label for="email">{t('email')}</label>
                                    <p className="collection-desc">{t('company_email')}</p>
                                    <input value={formData.email} onChange={handleChange} name='email' id="email" type="email" placeholder="Enter Your Company Email" required />
                                </div>
                                <div className="form-grp">
                                    <label for="phone">{t('phone')}</label>
                                    <p className="collection-desc">{t('company_phone')}</p>
                                    <input value={formData.phone} onChange={handleChange} name='phone' id="phone" type="text" placeholder="Enter Your Phone Number" required />
                                </div>
                                <div className="form-grp">
                                    <label for="company">{t('company')}</label>
                                    <p className="collection-desc">{t('company_name')}</p>

                                    <input value={formData.company} onChange={handleChange} name='company' id="company" type="text" placeholder="Enter Your Company Name" required />
                                </div>
                                <div className="form-grp">
                                    <label for="royalty">{t('royalty')}{' '}({formData.royalty}%)</label>
                                    <p className="collection-desc">{t('royalty_tip_collection')}</p>
                                    <Form.Range
                                        value={formData.royalty}
                                        onChange={handleChange}
                                        name='royalty' id="royalty"
                                        style={{
                                            borderColor:
                                                formData.royalty > 75
                                                    ? "red"
                                                    : formData.royalty > 50
                                                        ? "orange" : "#2A2547",
                                            backgroundColor: "#2A2547",

                                        }}
                                    />
                                    {formData.royalty > 30 ? (
                                        <span className="input-error-text">
                                            {t('royalty_alert')}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {/* HIDE OLD UI */}
                                    {/* <input value={formData.royalty} onChange={handleChange} name='royalty' id="royalty" type="number" placeholder="(10%)" required /> */}
                                </div>
                                {/* <div>
                                    <label for="royalty">Creators(max is 3)</label>
                                    <input type="text" id="member" name="member" value="1" />Creators(max is 3) <br />
                                    <button id="filldetails" onclick="addFields()">Add creators</button>
                                    <div id="container" />
                                    </div> */}
                                {/* <div class="form-grp">
                                    <label for="price">Royalty Split (max 4, Total 100)</label>
                                    <ReactTags
                                        tags={formData.royaltySplit}
                                        handleDelete={(i) => setFormData({ ...formData, royaltySplit: formData.royaltySplit.slice(0).filter((tag, index) => index !== i) })}
                                        handleAddition={(tag) => handleAddRoyaltySplit(tag)}
                                        allowUnique={false}
                                        inputFieldPosition="bottom"
                                        placeholder='Add Royalty Split'

                                    />
                                </div> */}
                                <div className="form-grp">
                                    <label for="creator">{t('creators')}</label>
                                    <p className="collection-desc">{t('creator_info')}</p>
                                    <p className="collection-desc">{t('creator_info_ext')}</p>
                                </div>
                                <div style={{
                                    border: '3px solid #5b5b5b',
                                    padding: '15px',
                                    borderRadius: '10px',
                                    marginBottom: '25px'
                                }}>
                                    {creators?.length != 0 && creators.map((creator, index) => (
                                        <div style={{
                                            display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                                        }}>
                                            <div className="form-grp" style={{ flex: 4 }}>
                                                <label for="creator">{t('creator')}{' '}{index + 1 === 1 ? "1 *" : (index + 1).toString()}</label>
                                                <input
                                                    value={index + 1 === 1 ? firstCreator : secondCreator}
                                                    onChange={handleSplits}
                                                    name={`royaltySplit${index + 1}`}
                                                    id={`royaltySplit${index + 1}`}
                                                    type="text"
                                                    placeholder={"Creator Public Key"}
                                                    required
                                                />
                                            </div>
                                            <div className="form-grp" style={{ flex: 1, marginLeft: '10px' }}>

                                                <label for="split">{t('split')}</label>
                                                <input
                                                    value={index + 1 === 1 ? firstSplit : secondSplit}
                                                    onChange={handleSplits}
                                                    name={`royaltyShare${index + 1}`}
                                                    id={`royaltyShare${index + 1}`}
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    max="100"
                                                    pattern="\b(0*(?:[1-9][0-9]?|100))\b"
                                                    placeholder="##%"
                                                    required
                                                />
                                            </div>
                                            {creators.length > 1 && index + 1 === 2 ?
                                                <button onClick={() => setCreators(creators.filter((item) => {
                                                    return item !== creators[index]
                                                }))} style={{ background: 'none', border: 'none' }}>
                                                    <div style={{ flex: 0, marginLeft: '10px', marginRight: '10px' }}>
                                                        <i
                                                            className="fa fa-minus-circle"
                                                            height="30px"
                                                            width="30px"
                                                            style={{
                                                                color: 'red', boxShadow: "0px 0px 2px 2px black",
                                                                borderRadius: '50%',
                                                            }}
                                                        />
                                                    </div>  </button> : <div style={{ flex: 0, marginLeft: '20px', marginRight: '10px' }}>
                                                    <i
                                                        className="fa fa-minus-circle"
                                                        height="30px"
                                                        width="30px"
                                                        style={{ color: 'transparent' }}
                                                    />
                                                </div>
                                            }

                                        </div>
                                    ))}
                                    {creators.length < 2 ?
                                        <button type="button"
                                            onClick={() => setCreators(creators => [...creators, { pub_key: publicKey?.toString(), split: formData.royaltySplit1 }])}
                                            className="btn-transparent"
                                        >
                                            <h5 style={{ justifyContent: 'center', alignItems: 'center', fontSize: '15px', color: '#e039fd', marginTop: 8 }}>+ {t('add_creator')}</h5>
                                        </button> : <></>}
                                </div>
                                {/* HIDE OLD UI */}
                                {/* 
                                <div className="form-grp" style={{ flex: 1 }}>
                                            <label for="creator">Creator 1 *</label>
                                            <input value={connected ? publicKey?.toString() : "plz connect your wallet"} onChange={handleChange} name='royaltySplit1' id="royaltySplit1" type="text" placeholder={connected ? publicKey?.toString() : "plz connect your wallet"} required disabled />
                                        </div>
                                <div className="form-grp" style={{ marginLeft: '10px' }}>

                                    <label for="split">Split</label>
                                    <input value={formData.royaltyShare1} onChange={handleChange} name='royaltyShare1' id="royaltyShare1" type="number" placeholder="##%" required />
                                </div>
                                <div className="form-grp">

                                    <label for="creator">Creator 2 (Optional)</label>
                                    <input value={formData.royaltySplit2} onChange={handleChange} name='royaltySplit2' id="royaltySplit2" type="text" placeholder={connected ? publicKey?.toString() : "plz connect your wallet"} />
                                </div>
                                <div className="form-grp">

                                    <label for="split">Split</label>

                                    <input value={formData.royaltyShare2} onChange={handleChange} name='royaltyShare2' id="royaltyShare2" type="number" placeholder="##%" />
                                </div>

                                <div className="form-grp">

                                    <label for="creator">Creator 3 (Optional)</label>
                                    <input value={formData.royaltySplit3} onChange={handleChange} name='royaltySplit3' id="royaltySplit3" type="text" placeholder={connected ? publicKey?.toString() : "plz connect your wallet"} />
                                </div>

                                <div className="form-grp">

                                    <label for="split">Split</label>
                                    <input value={formData.royaltyShare3} onChange={handleChange} name='royaltyShare3' id="royaltyShare3" type="number" placeholder="##%" />
                                </div> */}
                                <div className="form-grp">
                                    <label for="creator">{t('social_media')}</label>
                                    <p className="collection-desc">{t('social_media_info')}</p>
                                </div>
                                <div style={{
                                    border: '3px solid #5b5b5b',
                                    padding: '15px',
                                    borderRadius: '10px',
                                    marginBottom: '25px'
                                }}>
                                    <div style={{
                                        display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                                    }}>
                                        <div className="form-grp" style={{ flex: 1 }}>
                                            <label for="instagram">Instagram</label>
                                            <input value={formData.instagram} onChange={handleChange} name='instagram' id="instagram" type="text" placeholder="Official Instagram Link" />
                                        </div>
                                        <div className="form-grp" style={{ flex: 1, marginLeft: '10px' }}>
                                            <label for="twitter">Twitter</label>
                                            <input value={formData.twitter} onChange={handleChange} name='twitter' id="twitter" type="text" placeholder="Official Twitter Link" />
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                                    }}>
                                        <div className="form-grp" style={{ flex: 1 }}>
                                            <label for="facebook">Facebook</label>
                                            <input value={formData.facebook} onChange={handleChange} name='facebook' id="facebook" type="text" placeholder="Official Facebook Link" />
                                        </div>
                                        <div className="form-grp" style={{ flex: 1, marginLeft: '10px' }}>
                                            <label for="youtube">Youtube</label>
                                            <input value={formData.youtube} onChange={handleChange} name='youtube' id="youtube" type="text" placeholder="Official Youtube Link" />
                                        </div>
                                    </div>
                                    <div style={{
                                        display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                                    }}>
                                        <div className="form-grp" style={{ flex: 1 }}>
                                            <label for="tiktok">TikTok</label>
                                            <input value={formData.tiktok} onChange={handleChange} name='tiktok' id="tiktok" type="text" placeholder="Official TikTok Link" />
                                        </div>
                                        <div className="form-grp" style={{ flex: 1, marginLeft: '10px' }}>
                                            <label for="website">Website</label>
                                            <input value={formData.website} onChange={handleChange} name='website' id="website" type="text" placeholder="Official Company Website Link" />
                                        </div>
                                        {/* HIDE DISCORD */}
                                        {/* <div className="form-grp" style={{ flex: 1, marginLeft: '10px' }}>
                                            <label for="discord">Discord</label>
                                            <input value={formData.discord} onChange={handleChange} name='discord' id="discord" type="text" placeholder="Official Discord Link" />
                                        </div> */}
                                    </div>


                                </div>
                                <button onClick={submitForm} type="submit" className="btn">{t('submit')}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </Layout >
    )
}

export default ApplyCollection
