import { useContext, useEffect, useState } from "react";
import { AuctionHouseContext } from "../../../Providers/AuctionHouseContextProvider";
import MyNFTLoader from "../../Common/MyNFTs/MyNFTLoader";
import { Link } from "react-router-dom";
import { MetaplexContext } from "../../../Providers/MetaplexContextProvider";
import { solToUSD } from "../../../contexts/Coingecko";
import Marquee from "react-fast-marquee";
import { Identicon } from "../Nft/Identicon";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { findCandyMachine } from "../../../Api/Solana/findCandyMachine";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import Button from "react-bootstrap/esm/Button";
import Swal from "sweetalert2";


const LiveProduct = () => {
    const { loadingAuctions, listedNfts } = useContext(AuctionHouseContext);
    // const [ readyNFTs, setReadyNFTs ] = useState([]);
    // const [popNFTs, setPopNFTs] = useState(false);
    const { publicKey } = useContext(MetaplexContext);
    const metaplex = useContext(MetaplexContext);

    const [solPrice, setSolPrice] = useState(0);
    const [disableMint, setDisableMint] = useState(false);

    const { t } = useTranslation();
    const [liveCollectionNfts, setLiveCollectionNfts] = useState({})
    const [countDown, setCountDown] = useState("")
    const [candyMachines, setCandyMachines] = useState([])
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1500 }, //min:2000
            items: 4,
            slidesToSlide: 4,
        },
        desktop: {
            breakpoint: { max: 1500, min: 993 }, //max:2000
            items: 3,
            slidesToSlide: 3,//4,
        },
        // smallDesktop: {
        //   breakpoint: { max: 2000, min: 1024 },
        //   items: 2.5,
        //   slidesToSlide: 4,
        // },
        largeTablet: {
            breakpoint: { max: 992, min: 650 }, //max:1024
            items: 2,
            slidesToSlide: 2,
        },
        tablet: {
            breakpoint: { max: 650, min: 464 },
            items: 1.5,
            slidesToSlide: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,

        }
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button className="fa fa-angle-right"
            aria-label="Go to next slide"
            type="button"
            style={{
                position: 'absolute',
                outline: 'none',
                transition: 'all 0.5s',
                borderRadius: '5px',//'35px',
                zIndex: 10, //1000,
                border: 'none',
                background: 'rgba(0, 0, 0, 0.5)',
                minWidth: '43px',
                minHeight: '43px',
                opacity: 1,
                cursor: 1,
                right: 'calc(4% + 1px)',
                alignItems: 'center',
                justifyContent: 'center',
                color: "white",//"#E039FD" //"#6345ED",
            }} onClick={() => onClick()} >
            {/* <i className="fa-solid fa-chevron-right" height="30px" width="30px" style={{  }} /> */}
            {/* <i className="fa fa-angle-right" aria-hidden="true" style={{ height: 40 }} /> */}
        </button>;
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        // onMove means if dragging or swiping in progress.
        return <button className="fa fa-angle-left"
            aria-label="Go to previous slide"
            type="button"
            style={{
                position: 'absolute',
                outline: 'none',
                transition: 'all 0.5s',
                borderRadius: '5px',
                zIndex: 10,
                border: 'none',
                background: 'rgba(0, 0, 0, 0.5)',
                minWidth: '43px',
                minHeight: '43px',
                opacity: 1,
                cursor: 1,
                left: 'calc(4% + 1px)',
                alignItems: 'center',
                justifyContent: 'center',
                color: "white"
            }} onClick={() => onClick()} >
        </button>;
    };

    function timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " years";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " months";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " days";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hours";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }
    var aDay = 24 * 60 * 60 * 1000;
    useEffect(() => {
        const loadMyCM = async () => {
            //add candy machine address here
            const candyMachines = [
                new PublicKey("BWXn1MzHwZUh9EBRCN4RBL9ub4QMVHTCjwhMVkimawvK"),
                // new PublicKey("BWXn1MzHwZUh9EBRCN4RBL9ub4QMVHTCjwhMVkimawvK")
            ]
            candyMachines.forEach(async (candyMachine) => {
                const CM = await findCandyMachine({ metaplex: metaplex.metaplex, candyMachine })
                const collectionNft = CM && await metaplex.metaplex.nfts().findByMint({ mintAddress: CM.collectionMintAddress })

                setCandyMachines(prevCM => [...prevCM, { ...CM, collectionNft }])

            })


            // setLiveCollectionNfts(collectionNft)


        };


        // if (listedNfts.length !== 0) {
        //   setPopNFTs(true);
        // }
        // call the function
        loadMyCM()
            // make sure to catch any error
            .catch((err) => {
                console.log(err, 'error')
            });
    }, []);
    const [interval, setInterval] = useState(0)

    setTimeout(() => {
        setInterval(interval + 1)
    }, 1000); //20000);
    useEffect(() => {

        const liveDate = candyMachines.map(candyMachine => candyMachine?.goLiveDate?.toNumber() * 1000)
        liveDate.forEach(live => {
            setCountDown(
                Math.floor((new Date(live).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)) + "d " +
                Math.floor((new Date(live).getTime() - new Date().getTime()) % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)) + "h " +
                Math.floor((new Date(live).getTime() - new Date().getTime()) % (1000 * 60 * 60) / (1000 * 60)) + "m " +
                Math.floor((new Date(live).getTime() - new Date().getTime()) % (1000 * 60) / 1000) + "s "
            )
        })


    }, [interval])

    return (
        <section className="explore-products-area">
            <div className="container" >
                <div >
                    <div className="row mb-35">
                        <div className="col-md-7 col-sm-8" >
                            <div className="section-title" >
                                {i18n.language === 'en' ? <h2 className="title" >
                                    Launchpad{" "}
                                    {/* {t('latest_nfts')}{" "} */}
                                    <img src="assets/img/icons/title_icon01.png" alt="" />
                                </h2> :
                                    <div className="title">
                                        <h2 className="title" style={{ direction: 'rtl' }} >
                                            {t('latest_nfts') + " "}{" "}

                                        </h2>
                                        <img src="assets/img/icons/title_icon01.png" alt="" />
                                    </div>
                                }
                            </div>
                        </div>
                        {/* <div className="col-md-5 col-sm-4">
            <div className="section-button text-end">
              <a href="/#" className="btn filter-btn filter-toggle">
                <i className="fi-sr-filter" /> filter
              </a>
            </div>
          </div> */}
                    </div>
                </div>
                <div className="filter-category-wrap">
                    <div className="row">
                        <div className="col-12">
                            <ul className="category-list">
                                <li className="active">
                                    <a href="/index">
                                        <img src="assets/img/icons/cat_001.png" alt="" /> All

                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_01.png" alt="" /> Games
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_02.png" alt="" /> Art
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_03.png" alt="" /> Trading
                                        Cards
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_04.png" alt="" /> Music
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_05.png" alt="" /> Domain
                                        Names
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_06.png" alt="" /> Memes
                                    </a>
                                </li>
                                <li>
                                    <a href="/nft-marketplace">
                                        <img src="assets/img/icons/cat_07.png" alt="" />{" "}
                                        Collectibles
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div
                    className="row justify-content-center"
                >

                    {!candyMachines.length ? (
                        <MyNFTLoader numberOfSkeletons={4} />
                    ) : (<Carousel
                        responsive={responsive}
                        customRightArrow={<CustomRightArrow />}
                        customLeftArrow={<CustomLeftArrow />}
                        // containerClass="react-multi-carousel-list"
                        ssr
                    >
                        {   //checks for listedNfts and render items
                            candyMachines?.length && candyMachines?.map(candyMachine => {
                                {/* { console.log(candyMachine, "candy machine") } */ }
                                return (
                                    <div
                                        className="mx-4" //"col-xl-3 col-lg-4 col-md-6 col-sm-6"
                                        key={candyMachine?.collectionNft?.mint?.address}
                                    >

                                        <div className="top-collection-item" style={{
                                            maxWidth: 300,
                                            minWidth: 280
                                        }}>
                                            <div className="collection-item-top">
                                                <ul>
                                                    <li className="avatar">


                                                        <div className="thumb">
                                                            {candyMachine?.creators && <Identicon
                                                                alt=""
                                                                style={{
                                                                    width: '24px',
                                                                    height: '24px',
                                                                    marginRight: '10px',
                                                                    marginTop: '2px',
                                                                }}
                                                                address={candyMachine?.creators[0]?.address?.toString()} />}
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            By {candyMachine?.creators ? candyMachine?.creators[0]?.address?.toString()?.substring(0, 3) + "..." + candyMachine?.creators[0]?.address?.toString()?.slice(-3) : 'Undefined'}
                                                            <br></br>
                                                            {/* <span className="collection-item-date">{"Listed"} {timeSince(new Date(parseInt(data?.createdAt?.toString()) * 1000))}{" ago"}</span> */}
                                                        </div> </li>


                                                </ul>
                                            </div>
                                            <div className="collection-item-thumb">

                                                <span>
                                                    <img src={candyMachine?.collectionNft?.json?.image} alt="" style={{ width: "100%", height: "100%" }} />
                                                </span>

                                            </div>
                                            <label for="file">Total minted  <h6>{(candyMachine?.itemsMinted?.toNumber() / 100) * candyMachine?.itemsAvailable?.toNumber()} %</h6> </label>
                                            <progress id="file" value={candyMachine?.itemsMinted?.toNumber()} max={candyMachine?.itemsAvailable?.toNumber()}>
                                            </progress>
                                            <p>{candyMachine?.itemsRemaining?.toString()} / {candyMachine?.itemsAvailable?.toString()}</p>

                                            <div className="collection-item-content">
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    {candyMachine?.collectionNft?.name && candyMachine?.collectionNft?.name?.length > 30 ?
                                                        <Marquee gradient={false}  >
                                                            <h5
                                                                className="title"
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                }
                                                                }>{candyMachine?.collectionNft?.name}<span></span></h5>
                                                        </Marquee>
                                                        : <h5 className="title"> {candyMachine?.collectionNft?.name}  </h5>}
                                                    <h5 className="title">
                                                        <span className="price" style={{ fontSize: '16px' }}>
                                                            {candyMachine?.price?.basisPoints?.toNumber() / LAMPORTS_PER_SOL} {candyMachine?.price?.currency?.symbol?.toString()}
                                                        </span>
                                                    </h5>

                                                </div>
                                                <div style={{ justifyContent: 'flex-start' }}>
                                                    {candyMachine?.collectionNft?.json?.description && candyMachine?.collectionNft?.json?.description?.length > 30 ? <Marquee className="title" gradient={false} >
                                                        <h3 style={{
                                                            color: '#E039FD',
                                                            fontSize: '15px',
                                                            fontWeight: '200',
                                                            marginTop: '10px',
                                                            whiteSpace: 'nowrap',
                                                            // overflow: 'auto',
                                                        }}><span>{candyMachine?.collectionNft?.json?.description}</span></h3>
                                                    </Marquee> : <h3 style={{
                                                        color: '#E039FD',
                                                        fontSize: '14px',
                                                        fontWeight: '200',
                                                        marginTop: '10px',
                                                        whiteSpace: 'nowrap',
                                                        // overflow: 'auto',
                                                    }}><span>{candyMachine?.collectionNft?.json?.descriptionn}</span></h3>}
                                                </div>
                                            </div>
                                            <div className="collection-item-bottom">


                                                <ul>
                                                    <li
                                                        className="bid">

                                                        {new Date(candyMachine.goLiveDate.toNumber() * 1000) < Date.now()
                                                            ? <Button
                                                                disabled={disableMint}
                                                                onClick={async () => {

                                                                    try {

                                                                        const { nft } = await metaplex.metaplex.candyMachinesV2().mint({ candyMachine })
                                                                        console.log(nft, "nft minted")
                                                                        Swal.fire({
                                                                            title: "Nft minted successfully",
                                                                            icon: "success"
                                                                        })
                                                                    } catch (err) {
                                                                        Swal.fire({
                                                                            title: "Nft failed to mint",
                                                                            icon: "error",
                                                                            text: err
                                                                        })
                                                                    }


                                                                }}
                                                            >
                                                                Mint
                                                            </Button>
                                                            :
                                                            <h4>
                                                                {countDown}
                                                                {setDisableMint(true)}
                                                            </h4>


                                                        }
                                                    </li>

                                                </ul>


                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }</Carousel>
                    )}

                </div>
            </div>
        </section>
    );
};

export default LiveProduct;


