import React from 'react'
import AreaBg from './AreaBg'
import Banner from './Banner'
import DesignNews from './DesignNews'
import ExploreProduct from './ExploreProduct'
import LatestNews from './LatestNews'
import LatestProduct from './LatestProduct'
import LiveProduct from './LiveProduct'
import Testimonial from './Testimonial'
import TopCollection from './TopCollection'


const IndexOne = () => {

  return (
    <main>
      <Banner />
      {/* <TopCollection /> */}
      <LiveProduct />
      <ExploreProduct view={"collection"} />
      <ExploreProduct />
      <LatestProduct />
      <AreaBg />


      {/* <div style={{
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center"
      }}>
        <iframe
          src="https://www.spatial.io/s/nft-pluss-Lo-Fi-Place-639205960c56c50001720ce8?share=529147649823571961"
          width={1200}
          height={800}
          // sandbox='allow-scripts allow-modal'
          loading='lazy'
          title='Metaverse'
        ></iframe>
      </div> */}

      <LatestNews />


      {/* <DesignNews /> */}
      {/* <Testimonial /> */}
    </main>
  )
}

export default IndexOne