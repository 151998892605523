/* eslint-disable */
import { createContext, useEffect } from "react";
import {
  bundlrStorage,
  Metaplex,
  /* bundlrStorage,*/ walletAdapterIdentity,
} from "@metaplex-foundation/js";
import { nftStorage } from "@metaplex-foundation/js-plugin-nft-storage";
import { Connection } from "@solana/web3.js"; //clusterApiUrl
import { useWallet } from "@solana/wallet-adapter-react";

export const MetaplexContext = createContext(null);

const NFTPlusMainnetProduction =
  "https://floral-cool-sanctuary.solana-mainnet.quiknode.pro/"; //This is paid. Use this only for production on NFTPlus.
const PublicRPCDevnet = "https://devnet.genesysgo.net/"; //will be deprecated at any moment
const PublicRPCMainnet0 = "https://ssc-dao.genesysgo.net/"; //will be deprecated at any moment
const PublicRPCMainnet1 = "https://solana-api.projectserum.com/"; //This is reliable, for now

const MetaplexContextProvider: any = ({ children }) => {
  const connection = new Connection(NFTPlusMainnetProduction);
  const wallet = useWallet();
  const { publicKey } = wallet;
  const metaplex: any = new Metaplex(connection);

  // For uploading nft metadata to bundlr
  metaplex
    .use(walletAdapterIdentity(wallet))
    // .use(bundlrStorage({ address: "https://devnet.bundlr.network" })); //devnet
    .use(bundlrStorage({ address: "https://node1.bundlr.network" }));

  // nftStorage

  // metaplex
  //   .use(walletAdapterIdentity(wallet))
  //   .use(nftStorage({ token: process.env.NFT_STORAGE_API }));

  // const createCollection = async () => {
  // const collectionAuthority = metaplex.identity();
  // const { nft: collectionNft } = await metaplex.nfts().create({
  //   name: "My Collection NFT",
  //   uri: "https://example.com/path/to/some/json/metadata.json",
  //   sellerFeeBasisPoints: 0,
  //   isCollection: true,
  //   updateAuthority: collectionAuthority,
  // });
  // const updateNft = new Metaplex(connection).auctionHouse().bid({});

  // };

  // useEffect(() => {
  //   createCollection()

  //   return () => {
  //     createCollection()
  //   }
  // }, [metaplex])

  return (
    <MetaplexContext.Provider value={{ metaplex, publicKey, connection }}>
      {children}
    </MetaplexContext.Provider>
  );
};

export default MetaplexContextProvider;
