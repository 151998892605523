import { PublicKey, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { sol } from "@metaplex-foundation/js";
export const cancelBid = async ({
    tradeStateAddress,
    auctionHouse,
    metaplex,
}) => {
    try {

        const bid = await metaplex
            .auctionHouse().findBidByTradeState({
                tradeStateAddress: tradeStateAddress,
                auctionHouse
            });
        console.log(bid, "cancel bid")
        const { response } = await metaplex
            .auctionHouse()
            .cancelBid({ auctionHouse, bid })

        const withdrawResponse = await metaplex
            .auctionHouse()
            .withdrawFromBuyerAccount({
                auctionHouse,
                buyer: metaplex.identity(),
                amount: sol(bid.price.basisPoints / LAMPORTS_PER_SOL)

            });

        console.log(response, withdrawResponse);
        return response;
    } catch (err) {
        console.log(err);
    }
};
