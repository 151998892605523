import { PublicKey } from "@solana/web3.js";
export const findCandyMachine = async ({
    metaplex,
    candyMachine
}) => {
    try {
        const buyerBalance = await metaplex.candyMachinesV2().findByAddress({ address: candyMachine });

        return buyerBalance;
    } catch (err) {
        console.log(err);
    }
};
