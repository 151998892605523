import { React, useContext, useEffect, useState } from 'react'
import Banner from '../Explore/Banner'
import Layout from '../Layout/MainLayout'
import { MetaplexContext } from "../../../Providers/MetaplexContextProvider"
import CreateNFT from '../Mint/CreateNFT'
function CreateCollection() {

    const { metaplex } = useContext(MetaplexContext)
    return (
        <Layout>
            <Banner title={"Create Collection"} />
            <div className="App">
                <div className="container" style={{ minHeight: '650px' }}>
                    <CreateNFT isCollection={true} />
                </div>
            </div>
        </Layout>

    )
}

export default CreateCollection