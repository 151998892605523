import { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';

import { MetaplexContext } from '../../../Providers/MetaplexContextProvider';
import { AuctionHouseContext } from '../../../Providers/AuctionHouseContextProvider';
import { cancelBid } from '../../../Api/Solana/cancelBid';
import { bidNFT } from '../../../Api/Solana/bidNft';
import { acceptBid } from '../../../Api/Solana/AcceptBid';

export function MakeBidModal(props) {
    const { metaplex, publicKey } = useContext(MetaplexContext)
    const { auctionHouse } = useContext(AuctionHouseContext)
    // console.log(props?.allBids, "bids")
    const [allBids, setAllBids] = useState([])
    const [disableBid, setDisableBid] = useState(false)

    const getBid = async () => {
        const bids = await metaplex.auctionHouse().findBids({
            auctionHouse,
            mint: props.nft.mint.address
        });
        setAllBids(bids)
    }
    useEffect(() => {
        getBid()
    }, [props])


    // console.log(allBids, "allBids")
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Bid
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Place your bid</h4>
                <Container>
                    <Row>
                        <Col xs={12} md={8}>
                            <Form onSubmit={(event) => {
                                event.preventDefault();
                                bidNFT({ metaplex, auctionHouse, "mintAddress": props.nft.mint.address, price: event.target.bidPrice.value, tradeStateAddress: props.tradeStateAddress })
                            }}>
                                <Form.Group className="mb-3" controlId="bidPrice">
                                    {/* <Form.Label>Place your bid</Form.Label> */}
                                    <Form.Control
                                        type="number"
                                        placeholder={props?.maxPrice}
                                        autoFocus
                                        required
                                        // value={props?.maxPrice}
                                        max={props?.maxPrice}
                                        min={0.01}
                                        step={0.01}
                                    // onKeyUp={event => setDisableBid((event.target.value === "") || (event.target.value < allBids?.length
                                    //     //  && allBids?.filter(bid => bid.canceledAt === null && bid.purchaseReceiptAddress === null).sort((a, b) => b.price.basisPoints.toNumber() - a.price.basisPoints.toNumber()).shift().map(price => price.basisPoints / LAMPORTS_PER_SOL)
                                    // ))}
                                    />

                                </Form.Group>
                                <Button type="submit" id="submit"
                                // disabled={disableBid}
                                >Make a bid</Button>
                            </Form>
                            <p>bid price should not be higher than current listed price and minimum bid is 0.01 SOL</p>
                            <h4>Active bids</h4>
                            {allBids?.length &&
                                allBids?.filter(bid => bid.canceledAt === null && bid.purchaseReceiptAddress === null).sort((a, b) => b.price.basisPoints.toNumber() - a.price.basisPoints.toNumber())
                                    .map(bid => (
                                        <>
                                            <p>{bid.buyerAddress.toString().slice(0, 4) + "..." + bid.buyerAddress.toString().slice(-4) + " bidded " + bid.price.basisPoints.toNumber() / LAMPORTS_PER_SOL + " SOL on " + new Date(bid.createdAt.toString() * 1000).toLocaleDateString()}</p>
                                            {bid.buyerAddress.toString() === publicKey?.toString() &&
                                                <Button onClick={() => cancelBid({ "tradeStateAddress": bid.tradeStateAddress, auctionHouse, metaplex })}>Cancel bid</Button>}
                                            {console.log(props?.sellerAddress.toString(), "islister")}
                                            {props?.sellerAddress?.toString() === publicKey.toString() &&
                                                <Button onClick={() => acceptBid({ "listTradeStateAddress": props?.tradeStateAddress, "bidTradeStateAddress": bid.tradeStateAddress, auctionHouse, metaplex })}>Accept bid</Button>}

                                        </>
                                    ))}

                            <h4>Past bids</h4>
                            {allBids?.length && allBids?.filter(bid => bid.canceledAt !== null || bid.purchaseReceiptAddress !== null).sort((a, b) => b.price.basisPoints.toNumber() - a.price.basisPoints.toNumber()).map(bid => (
                                <>
                                    <p>{bid.buyerAddress.toString().slice(0, 4) + "..." + bid.buyerAddress.toString().slice(-4) + " bidded " + bid.price.basisPoints.toNumber() / LAMPORTS_PER_SOL + " SOL on " + new Date(bid.createdAt.toString() * 1000).toLocaleDateString()}</p>
                                </>
                            ))}
                        </Col>
                        <Col xs={6} md={4}>


                        </Col>
                    </Row>
                </Container>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

