import Layout from '../Layout/MainLayout'
import React, { useState } from 'react'
import Banner from '../Explore/Banner'
import { Link } from 'react-router-dom'
import ReactCardFlip from 'react-card-flip'

const SelectType = () => {
    const [showB2b, setShowB2b] = useState(false);
    const [showUser, setShowUser] = useState(false);

    return (

        <Layout>
            <Banner title={'Select Your NFT Type'} />
            {/* <SignUpButton /> */}
            <div className='row justify-content-center' style={{ minHeight: 650 }}>
                <div className="row justify-content-center col-xl-3 col-lg-4 col-md-6 col-sm-6 col-10">
                    <ReactCardFlip isFlipped={showB2b} flipDirection="horizontal" containerStyle={{ height: '270px', width: '270px' }}>
                        <button onMouseOver={() => { setShowB2b(true) }} style={{ background: 'none', border: 'none' }}><img src={"assets/img/cards/card.png"} height="270px" width="200px" /></button>
                        {/* disable link for now */}
                        <Link to='/apply'>
                            <button onMouseLeave={() => { setShowB2b(false) }} style={{

                                background: 'none', border: 'none'
                            }}>
                                <img src={"assets/img/cards/b2b.png"} height="270px" width="200px" />
                                <h4 className="mt-3">B2B (Coming Soon)</h4>
                            </button>
                        </Link>
                    </ReactCardFlip>
                    {/* HIDE OLD UI */}
                    {/* <Link to={{ pathname: '/apply' }}>
                            <div className="position-relative select-type my-4" style={{ height: 250, width: 300 }}>
                                <div className="d-flex justify-content-center">
                                    <div className="text-center">

                                        <img className="staticBusiness" src={"assets/img/icons/business.png"} style={{ color: 'transparent' }} height='100px' width="100px" />
                                        <img className="active" src={"assets/img/icons/Bussiness.gif"} height='100px' width="100px" />

                                        <h3 className="my-2" style={{ fontWeight: '400' }}>B2B</h3>
                                    </div>
                                </div>
                            </div>
                        </Link> */}
                </div>
                <div className='row justify-content-center col-xl-3 col-lg-4 col-md-6 col-sm-6'>
                    <ReactCardFlip isFlipped={showUser} flipDirection="horizontal" containerStyle={{ height: '270px', width: '270px' }}>
                        <button onMouseOver={() => { setShowUser(true) }} style={{ background: 'none', border: 'none' }}><img src={"assets/img/cards/card.png"} height="270px" width="200px" /></button>
                        <Link to='/mint-nft'>
                            <button onMouseLeave={() => { setShowUser(false) }} style={{ background: 'none', border: 'none' }}>
                                <img src={"assets/img/cards/user-nft.png"} height="270px" width="200px" />
                                <h4 className="mt-3">User</h4>
                            </button>
                        </Link>
                    </ReactCardFlip>
                    {/* HIDE OLD UI */}
                    {/* <Link to={{ pathname: '/mint-nft' }}>
                        <div className="position-relative select-type my-4" style={{ height: 250, width: 300 }}>
                            <div className="d-flex justify-content-center">
                                <div className="text-center">

                                    <img className="staticUser" src={"assets/img/icons/user.png"} color="transparent" height='100px' width="100px" />
                                    <img className="active" src={"assets/img/icons/Profileee.gif"} color="transparent" height='100px' width="100px" />
                                    <h3 className="my-2" style={{ fontWeight: '400' }}>USER</h3>

                                </div>
                            </div>
                        </div>
                    </Link> */}
                </div>
            </div>
        </Layout>

    )
}

export default SelectType