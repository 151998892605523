import { useTranslation } from "react-i18next";
import Banner from "../Components/Common/Explore/Banner";
import Layout from "../Components/Common/Layout/MainLayout";
import CreateNFT from "../Components/Common/Mint/CreateNFT";


const MintItem = () => {
  const { t } = useTranslation();
  return (
    <>
      <Layout>
        <Banner title={t('create_your_nft')} />
        <div className="App">
          <div className="container" style={{ minHeight: '650px' }}>
            <CreateNFT />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default MintItem;
