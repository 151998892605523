import { PublicKey } from "@solana/web3.js";
export const getBuyerBalance = async ({
  auctionHouse,
  metaplex,
  buyerAddress,
}) => {
  try {
    console.log(auctionHouse, "auctionHouse.toBuffer()");
    const buyerBalance = await metaplex.auctionHouse().getBuyerBalance({
      auctionHouse: auctionHouse.address,
      buyerAddress,
    });

    return buyerBalance;
  } catch (err) {
    console.log(err);
  }
};
