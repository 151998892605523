import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { sol } from "@metaplex-foundation/js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { cancelBid } from "./cancelBid";
import { bidNFT } from "./bidNft";
import { cancelListing } from "./cancelListing";

export const acceptBid = async ({
    metaplex,
    auctionHouse,
    listTradeStateAddress,
    bidTradeStateAddress,
    mintAddress,
    price,
    buyerAddress,
    sellerAddress
}) => {
    try {
        const steps = ["1", "2"];
        const Queue = Swal.mixin({
            progressSteps: steps,
            confirmButtonText: "Next >",

            // optional classes to avoid backdrop blinking between steps
            showClass: { backdrop: "swal2-noanimation" },
            hideClass: { backdrop: "swal2-noanimation" },
            allowOutsideClick: false,
        });

        await Queue.fire({
            title: "Transaction Approvals",
            text: "There will be two transaction requests that require approval to proceed with your purchase",
            currentProgressStep: 0,
            // optional class to show fade-in backdrop animation which was disabled in Queue mixin
            showClass: { backdrop: "swal2-noanimation" },
            allowOutsideClick: false,
            closed: false,
        });

        const listing = await metaplex
            .auctionHouse().findListingByTradeState({
                tradeStateAddress: listTradeStateAddress,
                auctionHouse
            });





        if (listing.purchaseReceiptAddress === null && listing.canceledAt === null) {

            const bid = await metaplex
                .auctionHouse().findBidByTradeState({
                    tradeStateAddress: bidTradeStateAddress,
                    auctionHouse
                });


            cancelListing({ tradeStateAddress: listTradeStateAddress, auctionHouse, metaplex })
                .then(async () => {
                    const { listing, sellerTradeState, receipt } = await metaplex
                        .auctionHouse()
                        .list({
                            auctionHouse,
                            mintAccount: bid.asset.mint.address,
                            price: sol(bid.price.basisPoints.toNumber() / LAMPORTS_PER_SOL),
                            printReceipt: true
                        })

                    // replace the receipt to execute sale
                    // const bid = await metaplex
                    //   .auctionHouse()
                    //   .findBidByReceipt({
                    //     receiptAddress: new PublicKey("AoU6osGHxW2ENPk35ZaNXawEEP45KthURJfKg2yDvfu8"),
                    //     auctionHouse
                    //   }
                    //   )
                    console.log(listing, bid)

                    console.log(bid, listing.price.basisPoints.toNumber());
                    try {
                        const tx = await metaplex
                            .auctionHouse()
                            .executeSale({ auctionHouse, listing, bid })


                        await Queue.fire({
                            icon: "success",
                            title: "Complete",
                            text: "All steps completed!",
                            currentProgressStep: 1,
                            confirmButtonText: "OK",
                            // optional class to show fade-in backdrop animation which was disabled in Queue mixin
                            showClass: { backdrop: "swal2-noanimation" },
                            allowOutsideClick: false,
                            closed: false,
                            // hideClass: { backdrop: 'swal2-noanimation' }
                        });
                        return tx;
                    } catch (error) {
                        console.log(error);
                        Swal.fire({
                            title: "Transaction failed.",
                            text: "Please try again. The Solana TPS was globally slow. ",
                            icon: "error",
                            confirmButtonText: "Close",
                        });
                    }
                })
            // console.log(bid.asset.mint.address, bid.price.basisPoints.toNumber() / LAMPORTS_PER_SOL)

        } else {
            Swal.fire("Invalid Listing")
        }



    } catch (error) {
        console.log(error);
    }
};
