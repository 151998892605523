import { useContext, useEffect, useState } from "react";
import { AuctionHouseContext } from "../../../Providers/AuctionHouseContextProvider";
import MyNFTLoader from "../../Common/MyNFTs/MyNFTLoader";
import { Link } from "react-router-dom";
import { MetaplexContext } from "../../../Providers/MetaplexContextProvider";
import { solToUSD } from "../../../contexts/Coingecko";
import Marquee from "react-fast-marquee";
import { Identicon } from "../Nft/Identicon";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import NFTCardSkelton from "../Nft/NftCardSkelton";
import { dummyCollections } from "../InnerPages/company/Company";

const ExploreProduct = (props) => {
  const { loadingAuctions, listedNfts, collectionNfts, currentNFTS } = useContext(AuctionHouseContext);
  // const [popNFTs, setPopNFTs] = useState(false)
  const { publicKey } = useContext(MetaplexContext);
  const metaplex = useContext(MetaplexContext);
  const display = props.view;
  const [solPrice, setSolPrice] = useState(0);

  const { t } = useTranslation();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1500 }, //min:2000
      items: 4,
      slidesToSlide: 4,
    },
    desktop: {
      breakpoint: { max: 1500, min: 993 }, //max:2000
      items: 3,
      slidesToSlide: 3,//4,
    },
    // smallDesktop: {
    //   breakpoint: { max: 2000, min: 1024 },
    //   items: 2.5,
    //   slidesToSlide: 4,
    // },
    largeTablet: {
      breakpoint: { max: 992, min: 650 }, //max:1024
      items: 2,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 650, min: 464 },
      items: 1.5,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,

    }
  };

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <button className="fa fa-angle-right"
      aria-label="Go to next slide"
      type="button"
      style={{
        position: 'absolute',
        outline: 'none',
        transition: 'all 0.5s',
        borderRadius: '5px',//'35px',
        zIndex: 10, //1000,
        border: 'none',
        background: 'rgba(0, 0, 0, 0.5)',
        minWidth: '43px',
        minHeight: '43px',
        opacity: 1,
        cursor: 1,
        right: 'calc(4% + 1px)',
        alignItems: 'center',
        justifyContent: 'center',
        color: "white",//"#E039FD" //"#6345ED",
      }} onClick={() => onClick()} >
    </button>;
  };

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const {
      onMove,
      carouselState: { currentSlide, deviceType }
    } = rest;
    // onMove means if dragging or swiping in progress.
    return <button className="fa fa-angle-left"
      aria-label="Go to previous slide"
      type="button"
      style={{
        position: 'absolute',
        outline: 'none',
        transition: 'all 0.5s',
        borderRadius: '5px',
        zIndex: 10,
        border: 'none',
        background: 'rgba(0, 0, 0, 0.5)',
        minWidth: '43px',
        minHeight: '43px',
        opacity: 1,
        cursor: 1,
        left: 'calc(4% + 1px)',
        alignItems: 'center',
        justifyContent: 'center',
        color: "white"
      }} onClick={() => onClick()} >
    </button>;
  };


  useEffect(() => {
    const loadMyNFT = async () => {
      let conversionPrice = await solToUSD();
      if (conversionPrice !== 404) {
        setSolPrice(conversionPrice);
      } //console.log("Solana Price: ", solPrice);
    };


    // if (listedNfts.length !== 0) {
    //   setPopNFTs(true);
    // }
    // call the function
    loadMyNFT()
      // make sure to catch any error
      .catch(() => {
        console.log('error')
      });
  }, [metaplex]);


  function CheckRoute() {
    try {
      const location = useLocation();
      // console.log('Current Route: ', .pathname);
      return location.pathname
    } catch (error) {
      console.log('cant get path', error);
    }
  };
  let currentRoute = CheckRoute();
  console.log('current route', currentRoute)
  if (display === "collection") {
    if (currentRoute === '/view-collection') {
      return (
        <section className="explore-products-area">
          <div className="container" >
            <div >
              <div className="row mb-35">
                <div className="col-md-7 col-sm-8" >
                  <div className="section-title" >
                    {i18n.language === 'en' ?

                      <h2 className="title" >
                        {/* Explore Nfts{" "} */}
                        {t('explore_nfts')}{" "}
                        <img src="assets/img/icons/title_icon01.png" alt="" />
                      </h2> : <h2 className="title" >
                        {/* Explore Nfts{" "} */}
                        {t('explore_nfts') + " "}{" "}
                        <span><img src="assets/img/icons/title_icon01.png" alt="" /></span>
                      </h2>}
                  </div>
                </div>
                {/* <div className="col-md-5 col-sm-4">
                  <div className="section-button text-end">
                    <a href="/#" className="btn filter-btn filter-toggle">
                      <i className="fi-sr-filter" /> filter
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="filter-category-wrap">
              <div className="row">
                {/* <div className="col-12">
                  <ul className="category-list">
                    <li className="active">
                      <a href="/index">
                        <img src="assets/img/icons/cat_001.png" alt="" /> All
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_01.png" alt="" /> Games
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_02.png" alt="" /> Art
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_03.png" alt="" /> Trading
                        Cards
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_04.png" alt="" /> Music
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_05.png" alt="" /> Domain
                        Names
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_06.png" alt="" /> Memes
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_07.png" alt="" />{" "}
                        Collectibles
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            {collectionNfts?.length === 0 ? <div className="row justify-content-center">
              {dummyCollections.map((data) => (
                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                  <NFTCardSkelton />
                </div>
              ))}
            </div> : <div className="row justify-content-center">
              {collectionNfts !== undefined && collectionNfts?.map((data) => (

                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                  key={data?.mint.address?.toString()}
                >
                  {/* {console.log('nft added')} */}
                  <div className="top-collection-item" style={{
                    maxWidth: 300,
                    minWidth: 280
                  }}>
                    <div className="collection-item-top">
                      <ul>
                        <li></li>
                        {/* <li className="avatar">

                          <div className="thumb">
                            {data?.creators[0] && <Identicon
                              alt=""
                              style={{
                                width: '24px',
                                height: '24px',
                                marginRight: '10px',
                                marginTop: '2px',
                              }}
                              address={data?.creators[0]?.address.toString()} />}
                          </div>

                          By {data?.creators && data?.creators[0]?.address?.toString()?.substring(0, 6)}{" "}
                          {data?.creators && data?.creators[0]?.address?.length >= 6 && "..."}
                        </li> */}
                      </ul>
                    </div>
                    <div className="collection-item-thumb">
                      <Link
                        to={`/detail-collection/${data?.mint?.address?.toString()}`}
                      >
                        <span>
                          <img src={data?.json?.image} alt="" style={{ width: "500%", height: "600%" }} />
                        </span>
                      </Link>
                    </div>
                    {/* {console.log('data info', data)} */}
                    <div className="collection-item-content">
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {data?.name && data?.name?.length > 30 ?
                          <Marquee gradient={false}  >
                            <h5
                              className="title"
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }
                              }>{data?.name}<span></span></h5>
                          </Marquee>
                          : <h5 className="title"> {data.name}  </h5>}
                        <h5 className="title">
                        </h5>

                      </div>
                      <div style={{ justifyContent: 'flex-start' }}>
                        {data?.json?.description && data?.json?.description?.length > 30 ? <Marquee className="title" gradient={false} >
                          <h3 style={{
                            color: '#E039FD',
                            fontSize: '15px',
                            fontWeight: '200',
                            marginTop: '10px',
                            whiteSpace: 'nowrap',
                            // overflow: 'auto',
                          }}><span>{data?.json?.description}</span></h3>
                        </Marquee> : <h3 style={{
                          color: '#E039FD',
                          fontSize: '14px',
                          fontWeight: '200',
                          marginTop: '10px',
                          whiteSpace: 'nowrap',
                          // overflow: 'auto',
                        }}><span>{data?.json?.description}</span></h3>}
                      </div>
                    </div>

                  </div>
                </div>
              )
              )
              }
            </div>}
          </div>
        </section>
      )
    } else {
      return (
        <section className="explore-products-area">
          <div className="container" >
            <div >
              <div className="row mb-35">
                <div className="col-md-7 col-sm-8" >
                  <div className="section-title" >
                    <button style={{ border: 'none', background: 'none' }}>
                      <Link to="/view-collection">
                        <h2 className="title" >
                          {t('new_collections')}{" "}
                          <img src="assets/img/icons/title_icon01.png" alt="" />
                        </h2>
                      </Link>
                    </button>
                  </div>
                </div>
                {/* <div className="col-md-5 col-sm-4">
              <div className="section-button text-end">
                <a href="/#" className="btn filter-btn filter-toggle">
                  <i className="fi-sr-filter" /> filter
                </a>
              </div>
            </div> */}
              </div>
            </div>
            <div className="filter-category-wrap">
              <div className="row">
                {/* <div className="col-12">
                  <ul className="category-list">
                    <li className="active">
                      <a href="/index">
                        <img src="assets/img/icons/cat_001.png" alt="" /> All
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_01.png" alt="" /> Games
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_02.png" alt="" /> Art
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_03.png" alt="" /> Trading
                        Cards
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_04.png" alt="" /> Music
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_05.png" alt="" /> Domain
                        Names
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_06.png" alt="" /> Memes
                      </a>
                    </li>
                    <li>
                      <a href="/nft-marketplace">
                        <img src="assets/img/icons/cat_07.png" alt="" />{" "}
                        Collectibles
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div
              className="row justify-content-center"
            >

              {!collectionNfts?.length ? (
                <MyNFTLoader numberOfSkeletons={4} />
              ) : (<Carousel
                infinite={false}
                responsive={responsive}
                // rtl={i18n.language === 'en' ? false : true}
                customRightArrow={<CustomRightArrow />}
                customLeftArrow={<CustomLeftArrow />}
                ssr

              >
                {collectionNfts?.length && collectionNfts?.map((data) => {
                  return (
                    <div
                      className="mx-4" //"col-xl-3 col-lg-4 col-md-6 col-sm-6"
                      key={data?.mint?.address?.toString()}
                    >

                      <div className="top-collection-item" style={{
                        maxWidth: 300,
                        minWidth: 280
                      }}>
                        <div className="collection-item-top">
                          <ul><li></li></ul>
                          {/* <ul>
                            <li className="avatar" style={{ width: 'auto' }}> */}

                          {/* hide identicon and creator name / pubkey */}
                          {/* <div className="thumb">
                                {data?.creators[0] && <Identicon
                                  alt=""
                                  style={{
                                    width: '24px',
                                    height: '24px',
                                    marginRight: '10px',
                                    marginTop: '2px',
                                  }}
                                  address={data?.creators[0]?.address.toString()} />}
                              </div> */}
                          {/* By {data?.creators && data?.creators[0]?.address?.toString()?.substring(0, 6)}{" "}
                              {data?.creators && data?.creators[0]?.address?.length >= 6 && "..."} */}
                          {/* {console.log('first creator', data.creators[0])} */}
                          {/* By {data?.creators && data?.creators[0] !== undefined ? data?.creators[0]?.address?.toString().substring(0, 3) + "..." + data?.creators[0]?.address?.toString().slice(-3) : 'Undefined'} */}
                          {/* </li>
                          </ul>*/}
                        </div>
                        <div className="collection-item-thumb">
                          <Link
                            to={`/detail-collection/${data?.mint?.address?.toString()}`}
                          >
                            <span>
                              <img src={data?.json?.image} alt="" style={{ width: "500%", height: "600%" }} />
                            </span>
                          </Link>
                        </div>

                        <div className="collection-item-content">
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            {data?.name && data?.name?.length > 30 ?
                              <Marquee gradient={false}  >
                                <h5
                                  className="title"
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                  }
                                  }>{data?.name}<span></span></h5>
                              </Marquee>
                              : <h5 className="title"> {data?.name}  </h5>}


                          </div>
                          <div style={{ justifyContent: 'flex-start' }}>
                            {data?.json?.description && data?.json?.description?.length > 30 ? <Marquee className="title" gradient={false} >
                              <h3 style={{
                                color: '#E039FD',
                                fontSize: '15px',
                                fontWeight: '200',
                                marginTop: '10px',
                                whiteSpace: 'nowrap',
                                // overflow: 'auto',
                              }}><span>{data?.json?.description}</span></h3>
                            </Marquee> : <h3 style={{
                              color: '#E039FD',
                              fontSize: '14px',
                              fontWeight: '200',
                              marginTop: '10px',
                              whiteSpace: 'nowrap',
                              // overflow: 'auto',
                            }}><span>{data?.json?.description}</span></h3>}
                          </div>
                        </div>

                      </div>

                    </div>
                  );
                })}</Carousel>
              )}

            </div>
          </div>
        </section>
      );
    }
  }
  if (currentRoute === '/nft-marketplace') {
    return (
      <section className="explore-products-area">
        <div className="container" >
          <div >
            <div className="row mb-35">
              <div className="col-md-7 col-sm-8" >
                <div className="section-title" >
                  {i18n.language === 'en' ?
                    <h2 className="title" >
                      {/* Explore Nfts{" "} */}
                      {t('explore_nfts')}{" "}
                      <img src="assets/img/icons/title_icon01.png" alt="" />
                    </h2> :
                    <h2 className="title" >
                      {/* Explore Nfts{" "} */}
                      {t('explore_nfts') + " "}{" "}
                      <span><img src="assets/img/icons/title_icon01.png" alt="" /></span>
                    </h2>}
                </div>
              </div>
              {/* <div className="col-md-5 col-sm-4">
                <div className="section-button text-end">
                  <a href="/#" className="btn filter-btn filter-toggle">
                    <i className="fi-sr-filter" /> filter
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          <div className="filter-category-wrap">
            <div className="row">
            </div>
          </div>
          <div className="row justify-content-center">
            {!listedNfts.length ? (
              <MyNFTLoader numberOfSkeletons={20} />
            ) : listedNfts?.length && listedNfts?.map((data) => (
              <div
                // className="mx-4"
                className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-10"
                key={data?.mintAddress}
              >

                <div className="top-collection-item" style={{
                  maxWidth: 300,
                  minWidth: 280
                }}>
                  <div className="collection-item-top">
                    <ul>
                      <li className="avatar">

                        <div className="thumb">
                          {/* {console.log('data', data)} */}
                          {data?.creators && <Identicon
                            alt=""
                            style={{
                              width: '24px',
                              height: '24px',
                              marginRight: '10px',
                              marginTop: '2px',
                            }}
                            address={data?.creators[0]?.address.toString()} />}
                        </div>
                        {data?.creators && <Link style={{ color: '#e039fd' }} to={`/author-profile/` + data?.creators[0]?.address?.toString()} state={data?.creators[0]?.address?.toString()}>
                          {/* By {data?.creators && data?.creators[0]?.address?.substring(0, 6)}{" "}
                          {data?.creators && data?.creators[0]?.address?.length >= 6 && "..."} */}
                          By {data?.creators !== undefined ? data?.creators[0]?.address?.toString()?.substring(0, 3) + "..." + data?.creators[0]?.address?.slice(-3) : 'Undefined'}
                        </Link>}
                      </li>
                    </ul>
                  </div>
                  <div className="collection-item-thumb">
                    <Link
                      to={`/view-nft/${data?.mintAddress}`}
                    >
                      <span>
                        <img src={data?.image} alt="" style={{ width: "500%", height: "600%" }} />
                      </span>
                    </Link>
                  </div>

                  <div className="collection-item-content">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                      {data?.name && data?.name?.length > 30 ?
                        <Marquee gradient={false}  >
                          <h5
                            className="title"
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }
                            }>{data?.name}<span></span></h5>
                        </Marquee>
                        : <h5 className="title"> {data?.name}  </h5>}
                      <h5 className="title">
                        <span className="price" style={{ fontSize: '16px' }}>
                          {data?.price} {data?.currency?.symbol}
                        </span>
                      </h5>

                    </div>
                    <div style={{ justifyContent: 'flex-start' }}>
                      {data?.description && data?.description?.length > 30 ? <Marquee className="title" gradient={false} >
                        <h3 style={{
                          color: '#E039FD',
                          fontSize: '15px',
                          fontWeight: '200',
                          marginTop: '10px',
                          whiteSpace: 'nowrap',
                          // overflow: 'auto',
                        }}><span>{data?.description}</span></h3>
                      </Marquee> : <h3 style={{
                        color: '#E039FD',
                        fontSize: '14px',
                        fontWeight: '200',
                        marginTop: '10px',
                        whiteSpace: 'nowrap',
                        // overflow: 'auto',
                      }}><span>{data?.description}</span></h3>}
                    </div>
                  </div>
                  <div className="collection-item-bottom">
                    <ul>
                      <li
                        className="bid">
                        <Link
                          to={`/view-nft/${data?.mintAddress}`}
                          className="btn"
                        >
                          {publicKey
                            ? data?.creators && data?.creators[0]?.address ===
                              publicKey?.toString()
                              ? t('view')
                              : t('buy')
                            : t('buy')}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )
            )
            }
          </div>
        </div>
      </section>
    )
  } else {
    return (
      <section className="explore-products-area">
        <div className="container" >
          <div >
            <div className="row mb-35">
              <div className="col-md-7 col-sm-8" >
                <div className="section-title justify-content-start" >
                  {i18n.language === 'en' ?
                    <button style={{ border: 'none', background: 'none' }}>
                      <Link to="/nft-marketplace">
                        <h2 className="title" >
                          {t("explore_nfts")}{" "}
                          <img src="assets/img/icons/title_icon01.png" alt="" />
                        </h2>
                      </Link>
                    </button>
                    :
                    <div className="title">
                      <button style={{ border: 'none', background: 'none' }}>
                        <Link to="/nft-marketplace">
                          <h2 className="title" style={{ direction: 'rtl' }} >
                            {t("explore_nfts")}{" "}

                          </h2>
                          <img src="assets/img/icons/title_icon01.png" alt="" />
                        </Link>
                      </button>
                    </div>
                  }

                </div>

              </div>
              {/* <div className="col-md-5 col-sm-4">
            <div className="section-button text-end">
              <a href="/#" className="btn filter-btn filter-toggle">
                <i className="fi-sr-filter" /> filter
              </a>
            </div>
          </div> */}
            </div>
          </div>
          <div className="filter-category-wrap">
            <div className="row">
              <div className="col-12">
                <ul className="category-list">
                  <li className="active">
                    <a href="/index">
                      <img src="assets/img/icons/cat_001.png" alt="" /> All
                    </a>
                  </li>
                  <li>
                    <a href="/nft-marketplace">
                      <img src="assets/img/icons/cat_01.png" alt="" /> Games
                    </a>
                  </li>
                  <li>
                    <a href="/nft-marketplace">
                      <img src="assets/img/icons/cat_02.png" alt="" /> Art
                    </a>
                  </li>
                  <li>
                    <a href="/nft-marketplace">
                      <img src="assets/img/icons/cat_03.png" alt="" /> Trading
                      Cards
                    </a>
                  </li>
                  <li>
                    <a href="/nft-marketplace">
                      <img src="assets/img/icons/cat_04.png" alt="" /> Music
                    </a>
                  </li>
                  <li>
                    <a href="/nft-marketplace">
                      <img src="assets/img/icons/cat_05.png" alt="" /> Domain
                      Names
                    </a>
                  </li>
                  <li>
                    <a href="/nft-marketplace">
                      <img src="assets/img/icons/cat_06.png" alt="" /> Memes
                    </a>
                  </li>
                  <li>
                    <a href="/nft-marketplace">
                      <img src="assets/img/icons/cat_07.png" alt="" />{" "}
                      Collectibles
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
          // className="row justify-content-center"
          >

            {!listedNfts.length ? (
              <MyNFTLoader numberOfSkeletons={4} />
            ) : (<Carousel
              infinite={false}
              responsive={responsive}
              customRightArrow={<CustomRightArrow />}
              customLeftArrow={<CustomLeftArrow />}
              partialVisible
            // containerClass="react-multi-carousel-list"
            // ssr
            >
              {listedNfts?.length && listedNfts?.map((data) => {
                return (
                  <div
                    className="mx-4" //"col-xl-3 col-lg-4 col-md-6 col-sm-6"
                    key={data?.mintAddress}
                  >

                    <div className="top-collection-item" style={{
                      maxWidth: 300,
                      minWidth: 280
                    }}>
                      <div className="collection-item-top">
                        <ul>
                          <li className="avatar">


                            <div className="thumb">
                              {data?.creators && <Identicon
                                alt=""
                                style={{
                                  width: '24px',
                                  height: '24px',
                                  marginRight: '10px',
                                  marginTop: '2px',
                                }}
                                address={data?.creators[0]?.address.toString()} />}
                            </div>
                            {data?.creators && <Link style={{ color: '#e039fd' }} to={`/author-profile/` + data?.creators[0]?.address?.toString()} state={data?.creators[0]?.address?.toString()}>
                              {/* {console.log('yooo', data?.creators[0].address.toString())} */}
                              By {data?.creators !== undefined ? data?.creators[0]?.address?.toString()?.substring(0, 3) + "..." + data?.creators[0]?.address?.slice(-3) : 'Undefined'}
                            </Link>}
                          </li>
                        </ul >
                      </div >
                      <div className="collection-item-thumb">
                        <Link
                          to={`/view-nft/${data?.mintAddress}`}
                        >
                          <span>
                            <img src={data?.image} alt="" style={{ width: "500%", height: "600%" }} />
                          </span>
                        </Link>
                      </div>

                      <div className="collection-item-content">
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          {data?.name && data?.name?.length > 30 ?
                            <Marquee gradient={false}  >
                              <h5
                                className="title"
                                style={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                }
                                }>{data?.name}<span></span></h5>
                            </Marquee>
                            : <h5 className="title"> {data?.name}  </h5>}
                          <h5 className="title">
                            <span className="price" style={{ fontSize: '16px' }}>
                              {data?.price} {data?.currency?.symbol}
                            </span>
                          </h5>

                        </div>
                        <div style={{ justifyContent: 'flex-start' }}>
                          {data?.description && data?.description?.length > 30 ? <Marquee className="title" gradient={false} >
                            <h3 style={{
                              color: '#E039FD',
                              fontSize: '15px',
                              fontWeight: '200',
                              marginTop: '10px',
                              whiteSpace: 'nowrap',
                              // overflow: 'auto',
                            }}><span>{data?.description}</span></h3>
                          </Marquee> : <h3 style={{
                            color: '#E039FD',
                            fontSize: '14px',
                            fontWeight: '200',
                            marginTop: '10px',
                            whiteSpace: 'nowrap',
                            // overflow: 'auto',
                          }}><span>{data?.description}</span></h3>}
                        </div>
                      </div>
                      <div className="collection-item-bottom">
                        <ul>
                          <li
                            className="bid">
                            <Link
                              to={`/view-nft/${data?.mintAddress}`}
                              className="btn"
                            >
                              {publicKey
                                ? data?.creators && data?.creators[0]?.address ===
                                  publicKey?.toString()
                                  ? t('view')
                                  : t('buy')
                                : t('buy')}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div >

                  </div >
                );
              })}</Carousel >
            )}

          </div >
        </div >
      </section >
    );
  }
}



export default ExploreProduct;
