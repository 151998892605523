import { useContext, useEffect, useState } from "react";
import Banner from "../Components/Common/Explore/Banner";
import Layout from "../Components/Common/Layout/MainLayout";
import { fetchUserNft } from "../Api/Solana/nft";
import NFTList from "../Components/Common/MyNFTs/NFTList";
import { MetaplexContext } from "../Providers/MetaplexContextProvider";
import MyNFTLoader from "../Components/Common/MyNFTs/MyNFTLoader";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useWallet } from "@solana/wallet-adapter-react";
import { Identicon } from "../Components/Common/Nft/Identicon";
import { toMetaplexFileFromBrowser } from "@metaplex-foundation/js";
import { getMessaging, getToken } from "firebase/messaging";

import { requestForToken, onMessageListener, sendMessage, sendMessageListener } from '../../src/utils/firebase';
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import AuthorDetailsSkeleton from "../Components/Common/InnerPages/AuthorDetailsSkeleton";
import { EditProfileModal } from "../Components/Common/Modal/EditProfileModal";
import { AuctionHouseContext } from "../Providers/AuctionHouseContextProvider";
import { getBuyerBalance } from "../Api/Solana/getBuyerBalance";

const MyNFTs = () => {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [nfts, setNfts] = useState([]);
  const [nftsDone, setNftsDone] = useState(false);
  const [userVerified, setUserVerified] = useState(false);
  const [loader, setLoader] = useState();
  const { publicKey, connected, disconnect } = useWallet();
  const [userData, setUserData] = useState(undefined)
  const [showEditModal, setShowEditModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [balance, setBalance] = useState(undefined);
  const [escrowBalance, setEscrowBalance] = useState(0);
  const metaplex = useContext(MetaplexContext);
  const { connection } = useContext(MetaplexContext);
  const { auctionHouse } = useContext(AuctionHouseContext)
  const getUserSOLBalance = async () => {
    let fetchedBalance = await connection?.getBalance(publicKey);
    if (fetchedBalance === 0 || fetchedBalance === '0') {
      setBalance(fetchedBalance)

    } else if (fetchedBalance === undefined) {
      setBalance(0)
    } else {
      setBalance((fetchedBalance / LAMPORTS_PER_SOL).toFixed(5));
    }


  };
  useEffect(() => {
    (async () => {
      let escrow_balance = await getBuyerBalance({ auctionHouse, metaplex: metaplex.metaplex, buyerAddress: metaplex.metaplex.identity().publicKey })
      setEscrowBalance(escrow_balance?.basisPoints?.toNumber() / LAMPORTS_PER_SOL)
      console.log(escrow_balance.basisPoints.toString() / 1000000000, "SOL", "buyer escrow balance");

    })()

  }, [auctionHouse])


  const getUserProfile = async () => {

    // sendMessageListener({
    //   title: "test",
    //   body: "test",
    //   // icon : "",
    //   toToken: "eoRU1Ee_8EbP3a5ZEBkmYb:APA91bFOp9eGkcEwOnKw0XHAUCtjmQCDOHC0lgmmk4-Hk29B0GVSL6NNSyNUlRR2X_2qEAsW-g4dDriTgK0cCI02ZXzGkxGhjDXv8a-H-fCQJpR5Iq8i-_eOjkurEnpvYGGIsZwb4m1-",
    // })

    const data = { pub_key: publicKey?.toString() }

    // axios.get('http://54.90.239.124:3000/user/get_user_profile', {
    axios.get('https://api.nft-plus.me/user/get_user_profile', {
      // axios.get(`${process.env.REACT_APP_BASE_URL}user/get_user_profile`, {
      params: data
    })
      .then(function (response) {
        console.log("get user profile", response.data.tempUser);
        if (response.data?.und === null || response.data?.und === 'null') {
          setUserData({
            profile_image: "",
            username: publicKey?.toString().slice(0, 3) + "..." + publicKey?.toString().slice(-3),
            email: "",
            bio: "",
            instagram_handle: "",
            twitter_handle: "",
            facebook_handle: "",
            youtube_handle: "",
            tiktok_handle: "",
            discord_handle: "",
            website: "",
            signed_up: false,
          });
          console.log('default userData', userData)

        } else {
          console.log('fetched user', response.data.tempUser)
          setUserData({ ...response.data.tempUser, username: response.data.tempUser.name === "" ? publicKey?.toString().slice(0, 3) + "..." + publicKey?.toString().slice(-3) : response.data.tempUser.name })
          setUserVerified(response.data.tempUser.signed_up)
          console.log('userData', userData)

        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const setUserProfile = async (userdata, uri) => {

    // const token = await requestForToken()
    // console.log(token, "requestPermission notification token ")
    // console.log("setting user data", userdata)

    var data = JSON.stringify({
      "pub_key": publicKey.toString(),
      "name": userdata.username, //userdata.username === "" ? userData.username : userdata.username,
      "email": userdata.email, //userdata.email === "" ? userData.email : userdata.username,
      "bio": userdata.bio,//userdata.bio === "" ? userData.bio : userdata.bio,
      "profile_image": uri,
      "firebase_notif_token_id": null,
      "instagram_handle": userdata.instagram_handle, //userdata.instagram_handle === "" ? userData.instagram_handle : userdata.instagram_handle,
      "facebook_handle": userdata.facebook_handle, //userdata.facebook_handle === "" ? userData.facebook_handle : userdata.facebook_handle,
      "youtube_handle": userdata.youtube_handle, //userdata.youtube_handle === "" ? userData.youtube_handle : userdata.youtube_handle,
      "discord_handle": userdata.discord_handle, //userdata.discord_handle === "" ? userData.discord_handle : userdata.discord_handle,
      "tiktok_handle": userdata.tiktok_handle, //userdata.tiktok_handle === "" ? userData.tiktok_handle : userdata.tiktok_handle,
      "twitter_handle": userdata.twitter_handle, //userdata.twitter_handle === "" ? userData.twitter_handle : userdata.twitter_handle,
      "website": userdata.website, //userdata.website === "" ? userData.website : userdata.website,
      "signed_up": true,
    });
    console.log('data is', data);
    var config = {
      method: 'post',
      // url: `${process.env.REACT_APP_BASE_URL}user/add_user_profile`,
      url: 'https://api.nft-plus.me/user/add_user_profile',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log('response from API', response)
        console.log('updated user from API', response.data.user)
        if (response.status === 200) {



          Swal.fire({ icon: "success", title: "Successfully Updated" }).then(() => {
            setIsUpdating(false)
            setUserData({ ...response.data.user, username: response.data.user.name === "" ? publicKey?.toString().slice(0, 3) + "..." + publicKey?.toString().slice(-3) : response.data.user.name })
            setShowEditModal(false);

          })

        }

        // 
      })
      .catch(function (error) {
        console.log(error);
        setIsUpdating(false);

      });
  }


  useEffect(() => {
    setTimeout(() => {
      setLoader('connect_wallet')
    }, 2000);//20000);
    setLoader(t('loading'))


  }, [])

  useEffect(() => {
    const loadMyNFT = async () => {
      setLoading(true);
      const nfts = await fetchUserNft(metaplex);
      if (nfts !== null && nfts !== undefined) { setNfts(nfts); setLoading(false); } //setNftsDone(true); setSkeletonNumber();
    };

    // setUserVerified(false)

    // call the function
    loadMyNFT()
      // make sure to catch any error
      .catch(() => {
        setLoading(false);
      });

    getUserProfile()
    getUserSOLBalance();
    // setLoading(false);

  }, [metaplex]);

  // useEffect(() => {
  //   setUserData({})

  // }, [disconnect])



  const signIn = async () => {
    try {
      const message =
        ` nft-plus wants you to sign in with your Solana account: ${publicKey.toBase58()}

    Click "Sign" or "Approve" only means you have proved this wallet is owned by you.
    
    This request will not trigger any blockchain transaction or cost any gas fee.
    
    Use of our website and service are subject to our Terms of Service.
    
    URI: https://nft-plus.me/
    Version: 1
    Chain ID: solana-mainnet
    Issued At: ${new Date().toISOString()}`

      const singedMessage = await window
        .solana
        .signMessage(
          new TextEncoder().encode(message),
          'utf8'
        )
      if (singedMessage.publicKey?.toBase58() === publicKey?.toBase58()) {
        setUserVerified(true)
        var data = JSON.stringify({
          "pub_key": publicKey.toString(),
          "signed_up": true,
        });
        var config = {
          method: 'post',
          // url: `${process.env.REACT_APP_BASE_URL}user/add_user_profile`,
          url: 'https://api.nft-plus.me/user/add_user_profile',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(config)
          .then(function (response) {
            if (response.status === 200) {
              Swal.fire({
                icon: 'success',
                title: 'User verified',
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                setIsUpdating(false)
                setUserData({ ...response.data.user, signed_up: true })
                setUserVerified(true)

              })
            }

          })
          .catch(function (error) {
            console.log(error);
          });


      }
    } catch (error) {
      console.log(error);
      Swal.fire(`${error.message}`)
    }

  }


  const edit = async () => {
    Swal.fire({
      title: 'Edit Profile',
      width: '600',
      html: `<input type="text" id="username" class="swal2-input" placeholder="Username" required>
      <input type="text" id="email" class="swal2-input" placeholder="Email" required>
      <input type="text" id="twitter_handle" class="swal2-input" value=${userData.twitter_handle == undefined ? "" : userData.twitter_handle}>
      <input type="hidden" id="pubkey" class="swal2-input" value=${metaplex?.publicKey?.toBase58()}>
      <input type="file" id="profile_image" name="filename" required>
      
      `,
      confirmButtonText: 'Update',
      focusConfirm: false,
      preConfirm: () => {
        const username = Swal.getPopup().querySelector('#username').value
        const email = Swal.getPopup().querySelector('#email').value
        const twitter_handle = Swal.getPopup().querySelector('#twitter_handle').value
        const pubkey = Swal.getPopup().querySelector('#pubkey').value
        var profile_image = Swal.getPopup().querySelector('#profile_image').files;

        if (!username || !pubkey) {
          Swal.showValidationMessage(`Please enter username and email`)
        }
        if (!profile_image)
          Swal.showValidationMessage(`Please upload an image`)
        return { username: username, email: email, pubkey: pubkey, twitter_handle: twitter_handle, profile_image: profile_image }
      }
    }).then(async (result) => {
      const image = result.value.profile_image[0]



      const uri = await metaplex.metaplex
        .storage()
        .upload(await toMetaplexFileFromBrowser(image))



      setUserProfile(result.value, uri)

        .catch((error) => {
          Swal.fire(`
             ${error}
          `.trim())
        })



    })
  }





  return (
    <>
      <Layout>
        <Banner title={t('my_nfts')} />
        {metaplex?.publicKey?.toBase58() ?
          <div className="author-profile-area">
            <div className="container">
              <div className="row justify-content-center">
                {loading ? <AuthorDetailsSkeleton /> :
                  <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-10">
                    <aside className="author-profile-wrap">
                      <div className="author-profile-thumb">
                        {userData && (userData?.profile_image === "" || userData?.profile_image === undefined) ?
                          <Identicon
                            alt=""
                            style={{
                              width: '100%',
                              height: '100%',
                            }}
                            address={publicKey?.toString()}
                          /> : <img src={userData?.profile_image ?? "assets/img/test-data/default_prof.jpeg"} height="100%" width="100%" alt="" />}
                        {/* <img src="assets/img/others/author_profile.png" alt="" /> */}
                      </div>
                      <div className="author-info">
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                          <h5 className="title">
                            {userData && userData?.username === undefined && (publicKey !== undefined || publicKey.toString().length !== 0) ? publicKey.toString().slice(0, 3) + "..." + publicKey.toString().slice(-3) : userData?.username}
                            {/* <img src="assets/img/icons/star.svg" alt="" /> */}
                            {userVerified && <button
                              onClick={() => setShowEditModal(true)}
                              style={{ flexDirection: 'row', color: '#1696e7', fontSize: '14px', fontWeight: '500', textAlign: 'right', border: 'none', background: 'none' }}
                            >
                              <i className="fa fa-edit" color='#1696e7' />
                            </button>}
                          </h5>
                        </div>
                        {userVerified ? userData && (userData?.bio !== undefined && userData?.bio !== "") ? <p>{userData.bio}</p> :
                          <p style={{ fontStyle: 'italic' }}>Your bio will be displayed here. Edit this to update your bio</p> :
                          <p style={{ fontStyle: 'italic', position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }}>Your bio will be displayed here. Edit this to update your bio</p>
                        }
                      </div>

                      {userVerified && userData && userData?.signed_up ?
                        <ul className="author-collection">
                          <li>
                            <p>My Owned Assets</p>
                            <span>{nfts?.length ?? 0}</span>
                          </li>
                          <li>
                            <p>My Wallet Balance</p>
                            <span>{balance ?? 0} SOL</span>
                          </li>
                          <li>
                            <p>My Trade Balance</p>

                            <span>{escrowBalance ?? 0} SOL</span>
                          </li>
                        </ul>

                        : (
                          <ul className="author-collection" style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative'
                          }}>

                            <li style={{ position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }} > <p>My Owned Assets</p>
                              <span>27</span></li>
                            <li style={{ position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }} > <p>My Wallet Balance</p>
                              <span>20 SOL</span>
                            </li>
                            <li style={{
                              display: 'flex', justifyContent: 'center',
                              position: 'absolute', height: '100%', width: '100%',
                            }}>
                              <button
                                onClick={signIn}
                                style={{
                                  color: '#bb86fc',
                                  fontStyle: 'italic',

                                  fontSize: '18px', fontWeight: '600', border: 'none', background: 'none'
                                }}
                              >Sign In</button>
                            </li>
                          </ul>)

                      }

                      {userVerified && (userData && userData?.signed_up)
                        ?
                        ((userData?.facebook_handle !== '' && userData?.facebook_handle !== undefined) || (userData?.twitter_handle !== '' && userData?.twitter_handle !== undefined) || (userData?.tiktok_handle !== '' && userData?.tiktok_handle !== undefined) || (userData?.youtube_handle !== '' && userData?.youtube_handle !== undefined) || (userData?.instagram_handle !== '' && userData?.instagram_handle != undefined)) &&
                        <div className="author-social">
                          <h6 className="title">Follow Social Media :</h6>
                          <ul>
                            {
                              userData?.facebook_handle !== '' && userData?.facebook_handle !== undefined ?
                                <li><a href={userData.facebook_handle} target="_blank" rel="noreferrer"><div className="icon"><i className="fab fa-facebook-f" /></div> Facebook / {userData?.facebook_handle.includes('https://www.facebook.com/') ? "@" + userData?.facebook_handle.replace('https://www.facebook.com/', "").replace('/', "") : ""}</a></li>
                                : <></>}

                            {
                              userData?.twitter_handle !== '' && userData?.twitter_handle !== undefined ?
                                <li><a href={`https://twitter.com/${userData.twitter_handle}`} target="_blank" rel="noreferrer"><div className="icon"><i className="fab fa-twitter" /></div> Twitter / {userData?.twitter_handle.includes('https://www.twitter.com/') ? "@" + userData?.twitter_handle.replace('https://www.twitter.com/', "").replace('/', "") : ''}</a></li>
                                : <></>
                            }
                            {
                              userData?.instagram_handle !== '' && userData?.instagram_handle !== undefined ?
                                <li><a href={`https://www.instagram.com/${userData.instagram_handle}`} target="_blank" rel="noreferrer"><div className="icon"><i className="fab fa-instagram" /></div> Instagram / {userData?.instagram_handle.includes('https://www.instagram.com/') ? "@" + userData?.instagram_handle.replace('https://www.instagram.com/', "").replace('/', "") : ""}</a></li>
                                : <></>
                            }
                            {
                              userData?.youtube_handle !== '' && userData?.youtube_handle !== undefined ?
                                <li><a href={userData.youtube_handle} target="_blank" rel="noreferrer"><div className="icon"><i className="fab fa-youtube" /></div> Youtube / {userData?.youtube_handle.includes('https://www.youtube.com/') ? "@" + userData?.youtube_handle.replace('https://www.youtube.com/', "").replace('/', "") : ""}</a></li>
                                : <></>
                            }
                            {/* HIDE DISCORD*/}
                            {/*
                              userData?.discord_handle !== undefined ?
                                <li><a href={userData.discord_handle} target="_blank"><div className="icon"><i className="fab fa-discord" /></div> Discord / @{userData?.discord_handle.replace('https://www.discord.com/', "").replace('/', "")}</a></li>
                                : <></>
                            } */}
                            {
                              userData?.tiktok_handle !== '' && userData?.tiktok_handle !== undefined ?
                                <li><a href={userData.tiktok_handle} target="_blank" rel="noreferrer"><div className="icon"><i className="fab fa-tiktok" /></div> TikTok / {userData?.tiktok_handle.includes('https://www.tiktok.com/') ? "@" + userData?.tiktok_handle.replace('https://www.tiktok.com/@', "").replace('/', "") : ""}</a></li>
                                : <></>
                            }
                          </ul>
                        </div> :
                        <div className="author-social">
                          <h6 className="title" style={{ position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }}>Follow Social Media :</h6>

                          <ul>
                            <li style={{ position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }}><a href="/#"><div className="icon"><i className="fab fa-facebook-f" /></div> Facebook / @joys_Aoi</a></li>
                            <li style={{ position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }}><a href="/#"><div className="icon"><i className="fab fa-facebook-messenger" /></div> Messenger / @joys_Avi</a></li>
                            <li style={{ position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }}><a href="/#"><div className="icon"><i className="fab fa-whatsapp" /></div> Whatsapp / @joys_Avi</a></li>
                            <li style={{ position: 'relative', filter: 'blur(4px)', pointerEvents: 'none', cursor: 'default' }}><a href="/#"><div className="icon"><i className="fab fa-youtube" /></div> Youtube / @joys_Avi</a></li>
                          </ul>
                        </div>
                      }
                    </aside>
                  </div>
                }
                <div className="col-xl-9 col-lg-8">
                  {/* HIDE NFT FILTER */}
                  <div className="author-product-meta">
                    <ul>
                      {/* <li className="active" style={{ pointerEvents: 'none' }}><a href="/author-profile">All nfts</a></li>
                      <li style={{ pointerEvents: 'none' }}><a href="/author-profile">My Collections</a></li>
                      <li style={{ pointerEvents: 'none' }}><a href="/author-profile">Collectibles</a></li>
                      <li style={{ pointerEvents: 'none' }}><a href="/author-profile">Music</a></li> */}
                    </ul>
                  </div>
                  <div className="row justify-content-center" style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    { //check if theres pk
                      loading ?

                        <MyNFTLoader numberOfSkeletons={8} userProfile={true} />
                        : nfts?.length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px', marginBottom: '100px' }}><h3 className="text-center my-4">No NFTs Owned</h3></div> :
                          <NFTList data={nfts} />
                    } </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="App">
            <div className="container" style={{ minHeight: '950px', }}>
              <div style={{ flexDirection: 'column', display: 'flex' }}>
                <h3 className="text-center my-5">{t(loader)}</h3>
              </div>
            </div>
          </div>
        }
        <EditProfileModal
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          setUserProfile={setUserProfile}
          data={userData}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
        />
      </Layout>
    </>
  );
};

export default MyNFTs;