import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const AddNftModal = (props) => {
    // console.log(props.ownerNfts, "ownerNfts in add modal")

    const [selectedNfts, setSelectedNfts] = useState([])
    const [checked, setChecked] = useState(false)


    console.log(selectedNfts, "selectedNfts in add modal")

    const setData = () => {
        props.addNfts(selectedNfts)
        props.onHide()
        setSelectedNfts([])
    }

    return (
        <>
            {
                props.ownerNfts && <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Select Nfts
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            {props.isCollection && <Row>

                                {props.ownerNfts.length === 0 ? <h5 style={{ textAlign: 'center' }}>No minted NFTs found</h5> : props.ownerNfts.filter(collection => collection?.collectionDetails !== null).map((nft, index) => {


                                    return (
                                        <>
                                            <Col xs={6} md={4}>
                                                {/* <input type="checkbox" id={`checkbox${index}`} /> */}
                                                <div style={{ position: 'relative' }}>
                                                    {/* <label for={`checkbox${index}`} */}
                                                    <div onClick={() => {
                                                        if (!selectedNfts.includes(nft)) {
                                                            setSelectedNfts(prevNfts => [...prevNfts, nft])
                                                            // setChecked(true)

                                                        } else {
                                                            setSelectedNfts(selectedNfts.filter((item) => { return item !== nft }))
                                                            // setChecked(false)
                                                        }

                                                    }}
                                                    >
                                                        <div style={{ position: 'relative' }}>
                                                            <img src={nft?.json?.image} alt={"user nfts"} style={{ opacity: selectedNfts.includes(nft) ? 0.6 : 1 }} />
                                                            {selectedNfts.includes(nft) && <i className="fa fa-check-circle" style={{ position: 'absolute', bottom: 5, right: 5, color: 'blue', border: '2px solid var(--paragraph-text-color)', borderRadius: '20px', backgroundColor: 'var(--paragraph-text-color)' }} height="50px" width="50px" />}
                                                        </div>
                                                    </div>

                                                    {/* </label> */}
                                                </div>
                                                <h5>{nft?.json?.name}</h5>


                                            </Col>
                                        </>
                                    )
                                })}
                            </Row>}
                            {!props.isCollection && <Row>

                                {props.ownerNfts.length === 0 ? <h5 style={{ textAlign: 'center' }}>No minted NFTs found</h5> : props.ownerNfts.filter(
                                    (collection) =>
                                        collection?.collectionDetails === null &&
                                        collection?.collection === null
                                ).map((nft, index) => {


                                    return (
                                        <>
                                            <Col xs={6} md={4}>
                                                {/* <input type="checkbox" id={`checkbox${index}`} /> */}
                                                <div style={{ position: 'relative' }}>
                                                    {/* <label for={`checkbox${index}`} */}
                                                    <div onClick={() => {
                                                        if (!selectedNfts.includes(nft)) {
                                                            setSelectedNfts(prevNfts => [...prevNfts, nft])
                                                            // setChecked(true)

                                                        } else {
                                                            setSelectedNfts(selectedNfts.filter((item) => { return item !== nft }))
                                                            // setChecked(false)
                                                        }

                                                    }}
                                                    >
                                                        <div style={{ position: 'relative' }}>
                                                            <img src={nft?.json?.image} alt={"user nfts"} style={{ opacity: selectedNfts.includes(nft) ? 0.6 : 1 }} />
                                                            {selectedNfts.includes(nft) && <i className="fa fa-check-circle" style={{ position: 'absolute', bottom: 5, right: 5, color: 'blue', border: '2px solid var(--paragraph-text-color)', borderRadius: '20px', backgroundColor: 'var(--paragraph-text-color)' }} height="50px" width="50px" />}
                                                        </div>
                                                    </div>

                                                    {/* </label> */}
                                                </div>
                                                <h5>{nft?.json?.name}</h5>


                                            </Col>
                                        </>
                                    )
                                })}
                            </Row>}
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={setData}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>

    );
}

export default AddNftModal;