// eslint-disable
// @ts-nocheck
import { myNftAdapter } from "../../Adapter/Solana/nft";
import {
  PublicKey,
  toMetaplexFileFromBrowser,
  walletAdapterIdentity,
} from "@metaplex-foundation/js";
import { createSetAndVerifyCollectionInstruction } from "@metaplex-foundation/mpl-token-metadata";
import Swal from "sweetalert2";
import { useWallet } from "@solana/wallet-adapter-react";
import { Transaction } from "@solana/web3.js";

export const fetchUserNft = async (config) => {
  // console.log('config user: ', config);
  const myNfts = await config.metaplex.nfts().findAllByOwner({
    owner: config.publicKey,
  });

  const nfts = [];
  try {
    for (let x = 0; x < myNfts.length; x++) {
      const nft = myNfts[x];
      const jsonNft = await config.metaplex.nfts().load({ metadata: nft });
      nfts.push({ ...jsonNft });
    }
  } catch (error) {
    console.log(error);
  }
  return myNftAdapter(nfts);
};

export const fetchUserNftModel = async (config) => {
  // console.log('config user: ', config);
  const myNfts = await config.metaplex.nfts().findAllByOwner({
    owner: config.publicKey,
  });

  const nfts = [];
  try {
    for (let x = 0; x < myNfts.length; x++) {
      const nft = myNfts[x];
      const jsonNft = await config.metaplex.nfts().load({ metadata: nft });
      if (
        jsonNft?.creators[0]?.address.toString() ===
        config.metaplex.identity().publicKey.toString()
      ) {
        nfts.push({ ...jsonNft });
      }
    }
  } catch (error) {
    console.log(error);
  }
  return nfts;
};

export const fetchSellerNft = async (config, publicKey) => {
  console.log("user pubkey", publicKey);
  const myNfts = await config.metaplex.nfts().findAllByOwner({
    owner: publicKey, //'2eVAYwxrfZiPBQYVpjUKSgXPCLWEaACqaeo6j7ijK48G',
  });

  const nfts = [];
  try {
    for (let x = 0; x < myNfts.length; x++) {
      const nft = myNfts[x];
      // console.log("nft", nft);
      const jsonNft = await config.metaplex.nfts().load({ metadata: nft });
      nfts.push({ ...jsonNft });
      // console.log("nft from owner pubkey ", jsonNft);
    }
  } catch (error) {}

  return myNftAdapter(nfts);
};

export const fetchCollectionNft = async (config) => {
  // console.log(config.publicKey);
  const myNfts = await config.metaplex.nfts().findAllByOwner({
    owner: config.address,
  });

  const nfts = [];
  try {
    for (let x = 0; x < myNfts.length; x++) {
      const nft = myNfts[x];
      // console.log("nft", nft);
      const jsonNft = await config.metaplex.nfts().load({ metadata: nft });
      nfts.push({ ...jsonNft });
      // console.log("nft from owner pubkey ", jsonNft);
    }
  } catch (error) {}

  return myNftAdapter(nfts);
};

export const createNft = async (config, data) => {
  try {
    console.log("Minting NFT...");
    const steps = ["1", "2", "3"];
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    const Queue = Swal.mixin({
      progressSteps: steps,
      confirmButtonText: "Next >",

      // optional classes to avoid backdrop blinking between steps
      showClass: { backdrop: "swal2-noanimation" },
      hideClass: { backdrop: "swal2-noanimation" },
      //   backdrop: `
      //   rgba(0,0,123,0.4)
      //   url("../img/others/loading gif.gif")
      //   center
      //   no-repeat
      // `
    });

    const updatedMetaData = {
      ...data,
      image: await toMetaplexFileFromBrowser(data.image),
    };
    //@ts-ignore
    await Queue.fire({
      title: "Signature approvals.",
      text: "Firstly, we will need two signature approvals.",
      currentProgressStep: 0,
      allowOutsideClick: false,
      closed: false,
    });

    const { uri } = await config.metaplex
      .nfts()
      .uploadMetadata(updatedMetaData);

    //@ts-ignore
    await Queue.fire({
      title: "Transaction Approval",
      text: "Secondly, we will need your transaction approval",
      currentProgressStep: 1,
      allowOutsideClick: false,
      closed: false,
    });

    const { nft } = await config.metaplex.nfts().create({
      uri: uri,
      isMutable: true,
      name: data.name,
      symbol: data.symbol,
      sellerFeeBasisPoints: data.seller_fee_basis_points,
    });
    delay(30000);
    console.log("NFT Minted");

    //@ts-ignore
    await Queue.fire({
      icon: "success",
      title: "Congratulations!",
      text: "Your NFT has been successfully created",
      currentProgressStep: 2,
      confirmButtonText: "OK",
      allowOutsideClick: false,
      closed: false,
    });
    return nft;
  } catch (err) {
    console.log(err);
    Swal.fire({
      icon: "error",
      title: "Failed to create NFT",
      text: `${err}`,
      confirmButtonText: "OK",
    });
  }
};

export const createCollectionNft = async (
  config,
  data,
  wallet,
  addNftsToCollection,
  collectionNft
) => {
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  console.log(addNftsToCollection, "addedNfts");
  try {
    if (collectionNft.length) {
      console.log("Minting Collection NFT...");
      const steps = ["1", "2", "3"];

      const Queue = Swal.mixin({
        progressSteps: steps,
        confirmButtonText: "Next >",

        // optional classes to avoid backdrop blinking between steps
        showClass: { backdrop: "swal2-noanimation" },
        hideClass: { backdrop: "swal2-noanimation" },
        //   backdrop: `
        //   rgba(0,0,123,0.4)
        //   url("../img/others/loading gif.gif")
        //   center
        //   no-repeat
        // `
      });
      const nft = collectionNft[0];
      console.log(nft);
      let currentProgress = 1;

      Swal.fire({
        title: `Updating your Nfts`,
        text: `Adding your nft to collection.Please approve the transactions`,
        showConfirmButton: false,
      });

      const getLatestBlockhash = (
        await config.metaplex.rpc().getLatestBlockhash("finalized")
      ).blockhash;

      const txs = await Promise.all(
        addNftsToCollection.map(async (addNft, i) => {
          const tx = await config.metaplex
            .nfts()
            .builders()
            .update({
              nftOrSft: addNft,
              collection: nft.address,
              // collection: new PublicKey(
              //   "HvPmYeX2LT59fLqnd3wzAFeMfW3n3m5Bt4YsHFepseFq"
              // ),
              collectionAuthority: config.metaplex.identity(),
            })
            .toTransaction(getLatestBlockhash);
          console.log(tx);
          tx.feePayer = config.metaplex.identity().publicKey;
          tx.recentBlockhash = getLatestBlockhash;
          // tx.partialSign(config.metaplex.identity());
          return tx;
        })
      );
      const signedTxs = await wallet.signAllTransactions(txs);
      const hashes = await Promise.all(
        signedTxs.map(
          async (tx) => await config.metaplex.rpc().sendTransaction(tx)
        )
      );

      // const confirmedTx = await Promise.all(
      //   hashes.map(async (h) => await config.metaplex.rpc().confirmTransaction(h))
      // );

      if (hashes.length) {
        Swal.close();
        console.log(hashes);
        //@ts-ignore
        Queue.fire({
          icon: "success",
          title: "Congratulations!",
          text: "Your Collection has been successfully created",
          currentProgressStep: 2,
          confirmButtonText: "OK",
          allowOutsideClick: false,
          closed: false,
        }).then(function () {
          //@ts-ignore
          window.location = "/my-nfts";
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed adding nfts to collection, Please try again later",
          confirmButtonText: "OK",
        });
      }
    } else {
      data.properties.creators.push({
        address: new PublicKey("BJ5sBNC7QVRnnUrqqkUuipRAVWPS3HPJapafUyrc3Mxd"),
        share: "0",
      });

      const updatedMetaData = {
        ...data,
        image: await toMetaplexFileFromBrowser(data.image),
      };
      console.log(updatedMetaData, "updatedMetaData");
      //test mint collecion

      // (async () => {
      //   const { nft: collectionNft } = await config.metaplex.nfts().create({
      //     name: "My Collection NFT",
      //     uri: "https://example.com/path/to/some/json/metadata.json",
      //     sellerFeeBasisPoints: 0,
      //     isCollection: true,
      //   });
      //   console.log(collectionNft, "test collection nft");
      // })();

      //@ts-ignore
      await Queue.fire({
        title: "Signature approvals.",
        text: "Firstly, we will need two signature approvals.",
        currentProgressStep: 0,
        allowOutsideClick: false,
        closed: false,
      });
      const { uri } = await config.metaplex
        .nfts()
        .uploadMetadata(updatedMetaData);

      //@ts-ignore
      await Queue.fire({
        title: "Transaction Approval",
        text: "Secondly, we will need your transaction approval",
        currentProgressStep: 1,
        allowOutsideClick: false,
        closed: false,
      });
      // console.log(data, uri, "nft data")

      const { nft } = await config.metaplex.nfts().create({
        uri: uri,
        isMutable: true,
        name: data.name,
        symbol: data.symbol,
        creators: data.properties.creators,
        sellerFeeBasisPoints: 0,
        isCollection: true,
      });
      await delay(30000);
      console.log(nft);
      let currentProgress = 1;

      Swal.fire({
        title: `Updating your Nfts`,
        text: `Adding your nft to collection.Please approve the transactions`,
        showConfirmButton: false,
      });

      const getLatestBlockhash = (
        await config.metaplex.rpc().getLatestBlockhash("finalized")
      ).blockhash;

      const txs = await Promise.all(
        addNftsToCollection.map(async (addNft, i) => {
          const tx = await config.metaplex
            .nfts()
            .builders()
            .update({
              nftOrSft: addNft,
              collection: nft.address,
              // collection: new PublicKey(
              //   "HvPmYeX2LT59fLqnd3wzAFeMfW3n3m5Bt4YsHFepseFq"
              // ),
              collectionAuthority: config.metaplex.identity(),
            })
            .toTransaction(getLatestBlockhash);
          console.log(tx);
          tx.feePayer = config.metaplex.identity().publicKey;
          tx.recentBlockhash = getLatestBlockhash;
          // tx.partialSign(config.metaplex.identity());
          return tx;
        })
      );
      const signedTxs = await wallet.signAllTransactions(txs);
      const hashes = await Promise.all(
        signedTxs.map(
          async (tx) => await config.metaplex.rpc().sendTransaction(tx)
        )
      );

      // const confirmedTx = await Promise.all(
      //   hashes.map(async (h) => await config.metaplex.rpc().confirmTransaction(h))
      // );

      if (hashes.length) {
        Swal.close();
        console.log(hashes);
        //@ts-ignore
        Queue.fire({
          icon: "success",
          title: "Congratulations!",
          text: "Your Collection has been successfully created",
          currentProgressStep: 2,
          confirmButtonText: "OK",
          allowOutsideClick: false,
          closed: false,
        }).then(function () {
          //@ts-ignore
          window.location = "/my-nfts";
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed adding nfts to collection, Please try again later",
          confirmButtonText: "OK",
        });
      }
    }

    // nft &&
    // addNftsToCollection.forEach(async (addNft, index) => {
    //   Swal.fire({
    //     title: `Updating your Nft #${index}`,
    //     text: `Adding your nft to collection.Please approve the transactions`,
    //     showConfirmButton: false,
    //   });
    //   // await delay(10000);

    //   // const tx_sig = await tx.sendAndConfirm(config.metaplex);

    //   // console.log(tx_sig, "tx_sig");
    //   // .then(async (tx) => {

    //   // const toTx = tx.toTransaction(getLatestBlockhash);
    //   // // toTx.recentBlockhash = getLatestBlockhash;
    //   // console.log(toTx, "toTx");

    //   // const tx_sig = await config.metaplex
    //   //   .rpc()
    //   //   .sendAndConfirmTransaction(toTx);

    //   Swal.close();
    //   config.metaplex
    //     .nfts()
    //     .refresh(addNft)
    //     .then((refreshedNft) => {
    //       console.log(refreshedNft, "updatedNft");

    //       if (currentProgress === addNftsToCollection.length) {
    //         //@ts-ignore
    //         Queue.fire({
    //           icon: "success",
    //           title: "Congratulations!",
    //           text: "Your Collection has been successfully created",
    //           currentProgressStep: 2,
    //           confirmButtonText: "OK",
    //           allowOutsideClick: false,
    //           closed: false,
    //         }).then(function () {
    //           //@ts-ignore
    //           window.location = "/my-nfts";
    //         });
    //       }
    //       currentProgress++;
    //     });
    //   // })
    //   // .catch((err) => {
    //   //   Swal.fire({
    //   //     icon: "error",
    //   //     title: "Failed adding nfts to collection, Please try again later",
    //   //     text: `${err}`,
    //   //     confirmButtonText: "OK",
    //   //   });
    //   // });

    //   //@ts-ignore
    //   // Queue.fire({
    //   //   title: `Verify your Nft #${index}`,
    //   //   text: "We will need you to verify your Nft.",
    //   //   currentProgressStep: 2,
    //   //   confirmButtonText: "OK",
    //   //   allowOutsideClick: false,
    //   //   closed: false,
    //   // });

    //   // config.metaplex.nfts().verifyCollection({
    //   //   mintAddress: updatedNft.address,
    //   //   collectionMintAddress: nft.collection.address,
    //   // });

    //   // pack  all the txs and send all at once
    //   // const tx = new createSetAndVerifyCollectionInstruction({
    //   //   collection: nft.address,
    //   //   collectionAuthority: config.metaplex.identity(),
    //   //   collectionMasterEditionAccount: nft.edition.address,
    //   //   collectionMint: nft.mint.address,
    //   //   metadata: nft.metadataAddress,
    //   //   payer: config.metaplex.identity().publicKey,
    //   //   updateAuthority: config.metaplex.identity(),
    //   // });
    // });
    // return nft;
  } catch (err) {
    console.log(err);
    Swal.fire({
      icon: "error",
      title: "Failed to create Collection, Please try again later",
      text: `${err}`,
      confirmButtonText: "OK",
    });
  }
};
