import { PublicKey } from '@solana/web3.js';
import React, { useContext, useState, useEffect } from 'react'
import Marquee from 'react-fast-marquee';
import { useParams } from "react-router";
import { Link } from 'react-router-dom';
import { fetchCollectionNft } from '../Api/Solana/nft';
import Banner from '../Components/Common/Explore/Banner';
import Layout from '../Components/Common/Layout/MainLayout';
import { Identicon } from '../Components/Common/Nft/Identicon';
import { AuctionHouseContext } from "../Providers/AuctionHouseContextProvider";
import { MetaplexContext } from "../Providers/MetaplexContextProvider"
import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { useWallet } from '@solana/wallet-adapter-react';
import MyNFTLoader from "../Components/Common/MyNFTs/MyNFTLoader";
const DetailCollection = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { publicKey } = useWallet()
    const { listedNfts } = useContext(AuctionHouseContext);
    const { metaplex, connection } = useContext(MetaplexContext)
    const [collectionNft, setCollectionNft] = useState([])

    const getCollectionNft = async () => {

        const mintAddress = new PublicKey(id)
        // const nft = await metaplex
        //     .nfts()
        //     .findByMint({ mintAddress })
        //     .run()
        // setCollectionNft(nft)

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`https://api.nft-plus.me/nft/get_nft/?mint_id=${mintAddress.toString()}&update_new=false`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result, "nft from mongo")

                setCollectionNft(result.nft)
            })
            .catch(error => console.log('nft metadata from mongo error:', error));


    }

    // const getListingCount = () => {

    //     listedNfts.length && listedNfts.forEach((listings, index) => {
    //         if (listings?.collection?.includes(id)) {
    //             console.log(listings)
    //             setListingCount(index)
    //         }
    //     })
    // }


    useEffect(() => {
        getCollectionNft()
        // getListingCount()
    }, [metaplex, connection, id])

    // useEffect(() => {
    //     getListingCount()
    // }, [listedNfts]);


    return (
        <>
            <Layout>
                <Banner title={collectionNft.name} />
                <section className="explore-products-area" style={{ minHeight: '780px' }}>
                    <div className="container" >
                        <div className="col-xl-4 col-lg-5 col-md-5 col-sm-6 col-10" style={{ margin: 'auto', maxWidth: '550px' }}>
                            <aside className="author-profile-wrap">
                                <div className="author-profile-thumb">
                                    <img src={collectionNft?.json?.image} height="100%" width="55%" alt="" style={{ clipPath: "circle()", display: 'flex', margin: 'auto' }} />
                                    {/* <img src="assets/img/others/author_profile.png" alt="" /> */}
                                </div>
                                <div className="author-info" >
                                    <h5 className="title" style={{ justifyContent: 'center' }}>{collectionNft?.name} <img src="assets/img/icons/star.svg" alt="" /></h5>
                                    {/* <h5 className="title">Olivia Jenar <img src="assets/img/icons/star.svg" alt="" /></h5> */}
                                    {/* <span>@ marketplace</span> */}
                                    <p style={{ justifyContent: 'center', display: 'flex' }}>{collectionNft?.json?.description}</p>
                                    {console.log(listedNfts, "listings count")}
                                    <p style={{ justifyContent: 'center', display: 'flex' }}>Listed :{listedNfts.filter(listing => listing?.collection?.includes(id)).length}</p>
                                    <p style={{ justifyContent: 'center', display: 'flex' }}>Floor :{listedNfts.filter(listing => listing?.collection?.includes(id)).sort((a, b) => a.price - b.price).map(floor => floor.price).shift()}</p>
                                    {/* <p style={{ justifyContent: 'center', display: 'flex' }}>Total Supply :{listedNfts.filter(listing => listing?.collection?.includes(id)).length}</p> */}
                                </div>
                            </aside>
                        </div>

                        <div className="filter-category-wrap">
                            <div className="row">
                                <div className="col-12">
                                    <ul className="category-list">
                                        <li className="active">
                                            <a href="/index">
                                                <img src="assets/img/icons/cat_001.png" alt="" /> All
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/nft-marketplace">
                                                <img src="assets/img/icons/cat_01.png" alt="" /> Games
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/nft-marketplace">
                                                <img src="assets/img/icons/cat_02.png" alt="" /> Art
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/nft-marketplace">
                                                <img src="assets/img/icons/cat_03.png" alt="" /> Trading
                                                Cards
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/nft-marketplace">
                                                <img src="assets/img/icons/cat_04.png" alt="" /> Music
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/nft-marketplace">
                                                <img src="assets/img/icons/cat_05.png" alt="" /> Domain
                                                Names
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/nft-marketplace">
                                                <img src="assets/img/icons/cat_06.png" alt="" /> Memes
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/nft-marketplace">
                                                <img src="assets/img/icons/cat_07.png" alt="" />{" "}
                                                Collectibles
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {listedNfts?.length === 0 ? <div className="row justify-content-center">
                            <div
                                className="row justify-content-center">
                                <MyNFTLoader numberOfSkeletons={8} />
                            </div>
                        </div> :
                            <div className="row justify-content-center">
                                {listedNfts
                                    ?.map((data, index) => (
                                        data?.collection?.includes(id) &&

                                        <div
                                            className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-10"
                                            key={data?.mintAddress}
                                        >

                                            <div className="top-collection-item">
                                                <div className="collection-item-top">
                                                    <ul>
                                                        <li className="avatar">

                                                            <div className="thumb">
                                                                {data?.creators[0] && <Identicon
                                                                    alt=""
                                                                    style={{
                                                                        width: '24px',
                                                                        height: '24px',
                                                                        marginRight: '10px',
                                                                        marginTop: '2px',
                                                                    }}
                                                                    address={data?.creators[0]?.address.toString()} />}
                                                            </div>

                                                            By {data?.creators && data?.creators[0]?.address?.substring(0, 6)}{" "}
                                                            {data?.creators && data?.creators[0]?.address?.length >= 6 && "..."}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="collection-item-thumb">
                                                    <Link
                                                        to={`/view-nft/${data?.mintAddress}`}
                                                    >
                                                        <span>
                                                            <img src={data?.image} alt="" style={{ width: "500%", height: "600%" }} />
                                                        </span>
                                                    </Link>
                                                </div>

                                                <div className="collection-item-content">
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        {data?.name && data?.name?.length > 30 ?
                                                            <Marquee gradient={false}  >
                                                                <h5
                                                                    className="title"
                                                                    style={{
                                                                        whiteSpace: 'nowrap',
                                                                        overflow: 'hidden',
                                                                    }
                                                                    }>{data?.name}<span></span></h5>
                                                            </Marquee>
                                                            : <h5 className="title"> {data?.name}  </h5>}
                                                        <h5 className="title">
                                                            <span className="price" style={{ fontSize: '16px' }}>
                                                                {data?.price} {data?.currency?.symbol}
                                                            </span>
                                                        </h5>

                                                    </div>
                                                    <div style={{ justifyContent: 'flex-start' }}>
                                                        {data?.description && data?.description?.length > 30 ? <Marquee className="title" gradient={false} >
                                                            <h3 style={{
                                                                color: '#E039FD',
                                                                fontSize: '15px',
                                                                fontWeight: '200',
                                                                marginTop: '10px',
                                                                whiteSpace: 'nowrap',
                                                                // overflow: 'auto',
                                                            }}><span>{data?.description}</span></h3>
                                                        </Marquee> : <h3 style={{
                                                            color: '#E039FD',
                                                            fontSize: '14px',
                                                            fontWeight: '200',
                                                            marginTop: '10px',
                                                            whiteSpace: 'nowrap',
                                                            // overflow: 'auto',
                                                        }}><span>{data?.description}</span></h3>}
                                                    </div>
                                                </div>
                                                <div className="collection-item-bottom">
                                                    <ul>
                                                        <li
                                                            className="bid">
                                                            <Link
                                                                to={`/view-nft/${data?.mintAddress}`}
                                                                className="btn"
                                                            >
                                                                {publicKey
                                                                    ? data?.creators && data?.creators[0]?.address ===
                                                                        publicKey?.toString()
                                                                        ? t('view')
                                                                        : t('buy')
                                                                    : t('buy')}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    )
                                }
                            </div>}
                    </div>
                </section>

            </Layout>
        </>
    )
}

export default DetailCollection